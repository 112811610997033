import React, {useEffect, useRef, useState} from 'react';
import { useHistory } from 'react-router-dom';
import {AppBar, CircularProgress, Tab, Tabs} from '@material-ui/core';
import {withStyles} from "@material-ui/core/styles";
import {
  getProjects,
} from "../../utils/api";
import PropTypes from "prop-types";
import ProjectDashboard from "./ProjectDashboard";
import ClusterDashboard from "./ClusterDashboard";

const styles = (theme) => ({
  paper: {
    padding: 15,
    marginTop: 10
  },
  main: {
    flex: 1,
    padding: theme.spacing(3, 3),
    background: '#eaeff1',
  },
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          style={{height: '100%'}}
          {...other}
      >
        {value === index && (
            <div style={{height: '100%'}}>{children}</div>
        )}
      </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function Dashboard(props) {
  const { classes, prjct } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isForbidden, setIsForbidden] = useState(false);
  const [projects, setProjects] = useState([]);
  const [value, setValue] = useState(0);

  const cancelRequest = useRef();
  let history = useHistory();

  const handleTabChange = (event, newValue) => {
    history.push(`/dashboard/${newValue}`);
    setValue(newValue);
  };

  const fetchProjects = async (loading) => {
    setIsError(false);
    setIsLoading(loading);
    try {
      const result = await getProjects();
      const pojectsData = result.data;

      if (!cancelRequest.current) {
        if (pojectsData && Object.keys(pojectsData).length > 0) {
          setProjects(pojectsData);
          setIsLoading(false);
        } else {
          setIsError(true);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setIsForbidden(true);
      } else if (!cancelRequest.current) {
        setIsError(true);
      }
    }
  };

  useEffect(() => {
    fetchProjects(true);
    setValue(prjct ? parseInt(prjct.split('_').pop()) : 0);
    history.push(`/dashboard/${prjct ? parseInt(prjct.split('_').pop()) : 0}`);
  }, [prjct]);

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isForbidden) {
      return (
          <>
            Access denied!
          </>
      );
    }

    if (isLoading) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    return (
        <>
          <AppBar
              component="div"
              className={classes.appBar}
              color="primary"
              position="static"
              elevation={5}
          >
            <Tabs value={value} onChange={handleTabChange}>
              <Tab textColor="inherit" label={'Cluster'} {...a11yProps(0)}/>
              { projects.map((project) => (
                  <Tab style={{color: project.expired ?  'darkgrey' : ''}} textColor="inherit" label={project.titleShort} value={project.id} {...a11yProps(project.id)}/>
              ))}
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <main className={classes.main}>
              <ClusterDashboard />
            </main>
          </TabPanel>
          { projects.map((project) => (
              <TabPanel key={project.id} value={value} index={project.id}>
                <main className={classes.main}>
                  <ProjectDashboard prjct={project} />
                </main>
              </TabPanel>
          ))}
        </>

    );
  };

  return renderData();
}

export default withStyles(styles)(Dashboard);