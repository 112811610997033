import React, {useEffect, useRef, useState} from 'react';
import { CircularProgress } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import {withStyles} from "@material-ui/core/styles";
import {
  createUser, deleteUser, getUsers, updateProjectForUser
} from "../../utils/api";
import DataGridActionBar from "../parts/DataGridActionBar";
import {formatTime} from "../../utils/timeHelper";
import AddUserDialog from "../dialogs/AddUserDialog";
import UserRoleChip from "../parts/UserRoleChip";
import { auth } from "../auth/firebase";
import ConfirmResetUserDialog from "../dialogs/ConfirmResetUserDialog";
import ChangeUserDialog from "../dialogs/ChangeUserDialog";

const styles = () => ({
  dataGrid: {
    backgroundColor: 'white'
  }
});


function UserSettings(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isForbidden, setIsForbidden] = useState(false);
  const [userList, setUserList] = useState('');

  const addConfirmationHandler =
      async (name, email, role, project) => {
        await createUser({
          'name': name,
          'email': email,
          'role': role,
          'project': project && project.id > 0 ? project : null
        }).then(() => {
          auth.sendPasswordResetEmail(email);
        });
        await fetchData();
      };

  const resetUserPassword =
      async (email) => {
        await auth.sendPasswordResetEmail(email).then(() => {
        });
        await fetchData();
      };

  const updateConfirmationHandler =
      async (id, uid, project) => {
        await updateProjectForUser(id,{
          'uid': uid,
          'projectId': project && project.id > 0 ? project.id : null
        })
        await fetchData();
      };

  const cancelRequest = useRef();

  const fetchData =
      async (loading, page = 0, size = 50) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getUsers(page - 1, size);
          const rolesData = result.data;

          if (!cancelRequest.current) {
            if (rolesData && Object.keys(rolesData).length > 0) {
              setUserList(rolesData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (error.response && error.response.status === 403) {
            setIsForbidden(true);
          } else if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false, 0);
  }, []);

  const removeUser = async (userId) => {
    await deleteUser(userId);
    await fetchData();
  };

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isForbidden) {
      return (
          <>
            Access denied!
          </>
      );
    }

    if (isLoading || !userList) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    return (
        <>
          <DataGrid
              hideFooter={true}
              sortingOrder={['asc', 'desc', null]}
              scrollbarSize={15}
              columnTypes={['string', 'number', 'date', 'dateTime']}
              columnBuffer={2}
              headerHeight={56}
              localeText={'enUS'}
              rowHeight={52}
              icons={[]}
              className={classes.dataGrid}
              rows={userList}
              columns={[
                { field: 'name', headerName: 'Name', flex: 1 },
                { field: 'email', headerName: 'Email', flex: 1 },
                { field: 'role', headerName: 'Role', flex: 1,
                  renderCell: (params) =>
                      <UserRoleChip role={params.row.role}/>
                },
                { field: 'project', headerName: 'Project', flex: 1,
                  renderCell: (params) =>
                      (params.row.project && params.row.project.titleShort
                          ? params.row.project.titleShort : '')
                },
                {
                  field: "dateRegistered",
                  headerName: "Creation date",
                  flex: 1,
                  renderCell: (params) =>
                      (formatTime(params.row.dateCreated, 'dd.LL.yyyy HH:mm'))
                },
                {
                  field: 'actions',
                  headerName: 'Action',
                  disableClickEventBubbling: true,
                  sortable: false,
                  disableColumnMenu: true,
                  flex: 1,
                  renderCell: (params) => {
                    return <>
                      <ChangeUserDialog user={params.row} confirmationHandler={updateConfirmationHandler}/>
                      <ConfirmResetUserDialog userEmail={params.row.email} confirmationHandler={resetUserPassword}/>
                      <DataGridActionBar
                          data={params.row}
                          type={'User'}
                          hideEdit={true}
                          confirmationHandler={(userId) => removeUser(userId)}>
                      </DataGridActionBar>
                    </>;
                  }
                }
              ]}
              density="compact"
              rowCount={userList.length}
          />
          <AddUserDialog
              confirmationHandler={addConfirmationHandler} />
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(UserSettings);