import React, {useEffect, useRef} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Description,
  DescriptionList,
  DescriptionTerm
} from "../parts/Description.parts";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {getProjects} from "../../utils/api";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ChangeProjectIcon from "@material-ui/icons/Settings";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

function ChangeUserDialog(props) {
  const { classes, confirmationHandler, user } = props;
  const [project, setProject] = React.useState({ id: 0, title: 'Global'});
  const [availableProjects, setAvailableProjects] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const cancelRequest = useRef();

  const fetchData =
      async () => {
        try {
          const result = await getProjects();
          const projectData = result.data;

          if (!cancelRequest.current) {
            if (projectData && Object.keys(projectData).length > 0) {
              setAvailableProjects(projectData);
            }
          }
        } catch (error) {
        }
      };

  useEffect(() => {
    fetchData();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setProject('0');
  };

  const handleConfirm = () => {
    confirmationHandler(user.id, user.uid, project);
    handleClose();
  };

  const handleProjectChange = (event) => {
    setProject(availableProjects.find(p => p.id === event.target.value));
  };

  return (
      <div>
        <Tooltip title="Change project for user">
          <IconButton onClick={handleClickOpen}>
            <ChangeProjectIcon className={classes.block} color="inherit" />
          </IconButton>
        </Tooltip>
        <Dialog fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Add user</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Change project for {user.email}
            </DialogContentText>
            <DescriptionList>
              <DescriptionTerm>Project</DescriptionTerm>
              <Description>
                <Select
                    labelId="project-select"
                    id="project-select"
                    value={project ? project.id + '' : '0'}
                    variant="outlined"
                    className={classes.selectEmpty}
                    onChange={handleProjectChange}
                >
                  <MenuItem value={'0'}>Cluster</MenuItem>
                  {availableProjects.length > 0 && availableProjects.map((p) => (
                      <MenuItem value={p.id}>{p.title}</MenuItem>
                  ))}
                </Select>
              </Description>
            </DescriptionList>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              Change
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(ChangeUserDialog)