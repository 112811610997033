import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

export default function AddRiskIndicatorValueDialog(props) {
  const { indicator, entity, confirmationHandler, action = 'create' } = props;
  const [open, setOpen] = useState(false);
  const [indicatorValueDescription, setIndicatorValueDescription] = useState('');
  const [indicatorValueComment, setIndicatorValueComment] = useState('');
  const [riskValue, setRiskValue] = useState('');
  const [quarter, setQuarter] = useState('3');
  const [year, setYear] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
    setIndicatorValueDescription(entity && entity.description ? entity.description : '');
    setIndicatorValueComment(entity && entity.comment ? entity.comment : '');
    setRiskValue(entity && entity.riskValue ? entity.riskValue : '');
    setYear(entity && entity.attributionDate ? new Date(entity.attributionDate).getFullYear() : '');
    setQuarter(entity && entity.attributionDate ? (new Date(entity.attributionDate).getMonth() + 1) + '' : '3');
  };

  const handleClose = () => {
    setOpen(false);
    clearFormData();
  };

  const handleConfirm = () => {
    let parsedQuarter = parseInt(quarter);
    if (parsedQuarter < 10) {
      parsedQuarter = '0' + quarter;
    }

    let attributionDate =
        new Date(`${year !== '' ? year : 2021}-${parsedQuarter}-01`)
        .toISOString().split('T')[0];

    action === 'create' ?
      confirmationHandler(
          indicator.id,
          indicatorValueDescription, attributionDate, riskValue) :
        confirmationHandler(
            entity.id,
            indicator.id,
            indicatorValueDescription, attributionDate, riskValue);
    handleClose();
  };

  const clearFormData = () => {
    setIndicatorValueDescription('');
    setQuarter('1');
    setYear('');
    setRiskValue('');
  };

  const onIndicatorValueDescriptionChange = (e) => {
    setIndicatorValueDescription(e.target.value);
  };

  const onRiskValueChange = (e) => {
    setRiskValue(e.target.value);
  };

  return (
      <div>
        {action === 'create' &&
          <Button variant="outlined" color="primary" onClick={handleClickOpen}>
            Add indicator value
          </Button>
        }
        { action === 'edit' &&
        <Tooltip title="Edit">
          <IconButton aria-label="edit"
                      onClick={handleClickOpen}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        }
        <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{ action === 'create' ? 'Add new' : 'Edit'} indicator value</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please fill in the necessary information
            </DialogContentText>
            <TextField
                autoFocus
                margin="normal"
                id="name"
                label="Description"
                type="text"
                value={indicatorValueDescription}
                onChange={onIndicatorValueDescriptionChange}
                fullWidth
                variant="outlined"
            />
            <FormControl variant="outlined" style={{ marginTop: '1rem', marginRight: '1rem', width: '15rem'}}>
              <InputLabel id="quarter-select-label">
                Quarter
              </InputLabel>
              <Select
                  labelId="quarter-select-label"
                  id="quarter-select"
                  label="Quarter"
                  value={quarter}
                  onChange={(e) => setQuarter(e.target.value)}
                  variant={'outlined'}
                  fullWidth
              >
                <MenuItem value={'3'}>1st</MenuItem>
                <MenuItem value={'6'}>2nd</MenuItem>
                <MenuItem value={'9'}>3rd</MenuItem>
                <MenuItem value={'12'}>4th</MenuItem>
              </Select>
            </FormControl>
            <TextField
                InputLabelProps={{ shrink: true }}
                margin="normal"
                id="males"
                label="Year"
                type="number"
                value={year}
                onChange={(e) => setYear(e.target.value)}
                variant="outlined"
            />
            <TextField
                margin="normal"
                id="value"
                label="Value"
                value={riskValue}
                onChange={onRiskValueChange}
                type="text"
                fullWidth
                variant="outlined"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              { action === 'create' ? 'Add' : 'Update'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}