import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Tooltip
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

function AddRiskIndicatorDialog(props) {
  const { classes, confirmationHandler, action = 'create', entity } = props;
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState('');
  const [abbreviation, setAbbreviation] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [risk, setRisk] = React.useState('');
  const [riskCategory, setRiskCategory] = React.useState('');
  const [responsible, setResponsible] = React.useState('');
  const [isNumeric, setIsNumeric] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    setName(entity && entity.name ? entity.name : '');
    setAbbreviation(entity && entity.abbreviation ? entity.abbreviation : '');
    setDescription(entity && entity.description ? entity.description : '');
    setRiskCategory(entity && entity.riskCategory ? entity.riskCategory : '');
    setRisk(entity && entity.risk ? entity.risk : '');
    setResponsible(entity && entity.responsible ? entity.responsible : '');
    setIsNumeric(entity && entity.isNumeric ? entity.isNumeric : false);
  };

  const handleClose = () => {
    setOpen(false);
    setName('');
    setAbbreviation('');
    setDescription('');
    setRisk('');
    setRiskCategory('');
    setResponsible('');
    setIsNumeric(false);
  };

  const handleConfirm = () => {
    action === 'create' ?
        confirmationHandler(name, abbreviation, description, riskCategory, risk, responsible, isNumeric) :
        confirmationHandler(entity.id, name, abbreviation, description, riskCategory, risk, responsible, isNumeric);
    handleClose();
  };

  const onNameChange = (e) => {
    setName(e.target.value);
  };

  const onAbbreviationChange = (e) => {
    setAbbreviation(e.target.value);
  };

  const onDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleRiskChange = (event) => {
    setRisk(event.target.value);
  };

  const handleRiskCategoryChange = (event) => {
    setRiskCategory(event.target.value);
  };

  const handleResponsibilityChange = (event) => {
    setResponsible(event.target.value);
  };

  const onIsNumericChange = (e) => {
    let checked = e.target.checked;
    setIsNumeric(checked);
    if (!checked) {
      setIsNumeric(false);
    }
  };

  return (
      <div>
        { action === 'create' &&
          <Fab
              variant="extended"
              size="medium"
              color="primary"
              aria-label="add"
              onClick={handleClickOpen}
              className={classes.fab}
              style={{position: 'fixed'}}
          >
            <AddIcon/>
            Add risk indicator
          </Fab>
        }
        { action === 'edit' &&
          <Tooltip title="Edit">
            <IconButton aria-label="edit"
                        onClick={handleClickOpen}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        }
        <Dialog fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{ action === 'create' ? 'Add new' : 'Edit'} risk indicator</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter the necessary information
            </DialogContentText>
            <TextField
                autoFocus
                margin="normal"
                id="name"
                label="Name"
                type="text"
                value={name}
                onChange={onNameChange}
                fullWidth
                variant="outlined"
            />
            <FormControl variant="outlined" className={classes.formControl} style={{ marginTop: '1rem', width: '15rem'}}>
              <InputLabel id="risk-type-select-label">
                Category
              </InputLabel>
              <Select
                  labelId="risk-type-select-label"
                  id="risk-type-select"
                  label="Category"
                  value={riskCategory}
                  onChange={handleRiskCategoryChange}
                  variant={'outlined'}
                  fullWidth
              >
                <MenuItem value={'POLITICAL'}>Political risks</MenuItem>
                <MenuItem value={'SAFETY_AND_SECURITY'}>Safety and security</MenuItem>
                <MenuItem value={'IMPLEMENTATION_RISKS'}>Implementation risks</MenuItem>
                <MenuItem value={'SUSTAINABILITY_RISKS'}>Sustainability risks</MenuItem>
                <MenuItem value={'CORRUPTION_RISKS'}>Corruption risks</MenuItem>
                <MenuItem value={'OTHER'}>Other</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl} style={{ marginTop: '1rem', width: '15rem'}}>
              <InputLabel id="risk-type-select-label">
                Risk
              </InputLabel>
              <Select
                  labelId="risk-select-label"
                  id="risk-select"
                  label="Risk"
                  value={risk}
                  onChange={handleRiskChange}
                  variant={'outlined'}
                  fullWidth
              >
                <MenuItem value={'LOW'}>Low</MenuItem>
                <MenuItem value={'MEDIUM'}>Medium</MenuItem>
                <MenuItem value={'HIGH'}>High</MenuItem>
              </Select>
            </FormControl>
            <TextField
                margin="normal"
                id="abbreviation"
                label="Short description"
                type="text"
                value={abbreviation}
                onChange={onAbbreviationChange}
                fullWidth
                variant="outlined"
            />
            <TextField
                margin="normal"
                id="description"
                label="Description"
                type="text"
                value={description}
                onChange={onDescriptionChange}
                rows={4}
                multiline
                fullWidth
                variant="outlined"
            />
            <FormGroup>
              <FormControlLabel control={<Checkbox checked={isNumeric} color="primary" onChange={onIsNumericChange} />} label="Risk indicator captures numeric data" />
            </FormGroup>
            <TextField
                margin="normal"
                id="responsibility"
                label="Responsibility"
                type="text"
                value={responsible}
                onChange={handleResponsibilityChange}
                fullWidth
                variant="outlined"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              { action === 'create' ? 'Create' : 'Update'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(AddRiskIndicatorDialog)