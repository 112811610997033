import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  formControl: {
    width: '70%',
  }
});

function AddProjectDialog(props) {
  const { classes, confirmationHandler } = props;
  const [title, setTitle] = React.useState('');
  const [shortTitle, setShortTitle] = React.useState('');
  const [projectNumber, setProjectNumber] = React.useState('');
  const [projectObjective, setProjectObjective] = React.useState('');
  const [partners, setPartners] = React.useState('');
  const [headOfProject, setHeadOfProject] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    confirmationHandler(title, shortTitle, projectNumber, projectObjective,
        headOfProject, partners);
    handleClose();
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleShortTitleChange = (event) => {
    setShortTitle(event.target.value);
  };

  const handleProjectNumberChange = (event) => {
    setProjectNumber(event.target.value);
  };

  const handleProjectObjectiveChange = (event) => {
    setProjectObjective(event.target.value);
  };

  const handleHeadOfProjectChange = (event) => {
    setHeadOfProject(event.target.value);
  };

  const handlePartnersChange = (event) => {
    setPartners(event.target.value);
  };

  return (
      <div>
        <Fab
            variant="extended"
            size="medium"
            color="primary"
            aria-label="add"
            onClick={handleClickOpen}
            className={classes.fab}
            style={{position: 'fixed'}}
        >
          <AddIcon/>
          Add project
        </Fab>
        <Dialog fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Add new project</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the basic project data
            </DialogContentText>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    autoFocus
                    margin="normal"
                    id="project-title"
                    label="Name"
                    type="text"
                    value={title}
                    variant={'outlined'}
                    onChange={handleTitleChange}
                    fullWidth
                />
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    margin="normal"
                    id="short-title"
                    label="Short name"
                    type="text"
                    value={shortTitle}
                    variant={'outlined'}
                    onChange={handleShortTitleChange}
                />
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    margin="normal"
                    id="project-number"
                    label="Project number"
                    type="text"
                    value={projectNumber}
                    variant={'outlined'}
                    onChange={handleProjectNumberChange}
                    fullWidth
                />
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    margin="normal"
                    id="project-objective"
                    label="Project objective"
                    type="text"
                    value={projectObjective}
                    variant={'outlined'}
                    rows={2}
                    multiline
                    onChange={handleProjectObjectiveChange}
                    fullWidth
                />
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    margin="normal"
                    id="head-of-project"
                    label="Head of project"
                    type="text"
                    value={headOfProject}
                    variant={'outlined'}
                    onChange={handleHeadOfProjectChange}
                    fullWidth
                />
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    margin="normal"
                    id="partners"
                    label="Partners"
                    type="text"
                    value={partners}
                    variant={'outlined'}
                    rows={2}
                    multiline
                    onChange={handlePartnersChange}
                    fullWidth
                />
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(AddProjectDialog)