import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import GridList from '@material-ui/core/GridList';
import IconButton from '@material-ui/core/IconButton';
import {
  createProject, deleteProject,
  getProjects, updateProject
} from "../../utils/api";
import {CircularProgress} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Paper from '@material-ui/core/Paper';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import ConfirmActionDialog from "../dialogs/ConfirmActionDialog";
import {
  Description,
  DescriptionList,
  DescriptionTerm
} from "../parts/Description.parts";
import EditableInput from "../input/EditableInput";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import AddProjectDialog from "../dialogs/AddProjectDialog";

const styles = () => ({
  contentWrapper: {
    margin: 'auto'
  },
  tableContainer: {
    margin: 'auto',
    marginTop: '2rem',
    marginLeft: '2rem',
    overflow: 'hidden',
  },
  tableHead: {
    background: '#F5F5F5',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  gridList: {
    paddingBottom: '1rem',
  },
  paper: {
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
});

const arrSum = arr => arr.reduce((a, b) => a + b.numericValue, 0);

const StyledLinearProgress = withStyles((theme) => ({
  root: {
    height: 24,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
  },
}))(LinearProgressWithLabel);

function LinearProgressWithLabel(props) {
  return (
      <Box display="flex" alignItems="center">
        <Box width="90%" mr={1}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">
            {props.current} / {props.target}
          </Typography>
        </Box>
      </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

function Row(props) {
  let { project, shouldOpen, removeHandler } = props;
  const [open, setOpen] = useState(shouldOpen);
  const classes = useRowStyles();
  const cancelRequest = useRef();
  const [stateProject, setStateActivity] = useState(project);
  const [projectBuffer, setProjectBuffer] = useState(project);

  const updateProjectDataAndDispatch = async (field, value) => {
    try {
      setProjectBuffer({
        ...projectBuffer,
        [field]: value,
      });
      const { data: projectData } = await updateProject(
          project.id,
          { [field]: value }
      );

      if (!cancelRequest.current) {
        setStateActivity(projectData);
      }
    } catch (error) {
      setProjectBuffer(projectBuffer);
    }
  };

  return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell style={{ paddingBottom: 0 }} colSpan={7}>
            <strong style={{color: stateProject.expired ? 'grey' : 'black'}}>{stateProject.title}</strong>
          </TableCell>
        </TableRow>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{stateProject.titleShort}</TableCell>
          <TableCell>{stateProject.projectNumber}</TableCell>
          <TableCell>{stateProject.headOfProject}</TableCell>
          <TableCell width={'10%'}>
            <ConfirmActionDialog action={'delete'} confirmationHandler={() => removeHandler(stateProject.id)}/>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
            <Collapse style={{marginBottom: '1rem'}} in={open} timeout="auto" unmountOnExit>
              <Paper elevation={0} variant="outlined" className={classes.paper}>
                <AppBar className={classes.toolbar}
                        position="static"
                        color="default"
                        elevation={0}>
                  <Toolbar>
                    <Typography variant="h6" gutterBottom component="div">
                      Project information
                    </Typography>
                  </Toolbar>
                </AppBar>
                <div className={classes.contentWrapper}>
                  <GridList cellHeight={'auto'} className={classes.gridList} cols={2}>
                    <DescriptionList>
                      <DescriptionTerm>Name</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="title"
                            value={stateProject.title}
                            disabled={false}
                            fullWidth={true}
                            size={'large'}
                            onSave={(fieldName, value) => updateProjectDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Short name</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="titleShort"
                            value={stateProject.titleShort}
                            disabled={false}
                            fullWidth={true}
                            size={'large'}
                            onSave={(fieldName, value) => updateProjectDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Number</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="projectNumber"
                            value={stateProject.projectNumber}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateProjectDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Objective</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="projectObjective"
                            value={stateProject.projectObjective}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateProjectDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Term</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="projectTerm"
                            value={stateProject.projectTerm}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateProjectDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Head of project</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="headOfProject"
                            value={stateProject.headOfProject}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateProjectDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Commissioning party</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="commissioningParty"
                            value={stateProject.commissioningParty}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateProjectDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Partners</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="executingAgency"
                            value={stateProject.executingAgency}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateProjectDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Project monitoring officer</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="projectMonitoringOfficer"
                            value={stateProject.projectMonitoringOfficer}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateProjectDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>DMS Link</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="dmsLink"
                            value={stateProject.dmsLink}
                            disabled={false}
                            fullWidth={true}
                            size={'large'}
                            onSave={(fieldName, value) => updateProjectDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Comments</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="comment"
                            value={stateProject.comment}
                            rows={4}
                            multiline
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateProjectDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                  </GridList>
                </div>
              </Paper>
              {stateProject.indicators && stateProject.indicators.filter(
                  function (indicator) {
                    return indicator.indicatorType === 'MODULE';
                  }).length > 0 &&
                <Paper elevation={0} variant="outlined" className={classes.paper}>
                  <AppBar className={classes.toolbar}
                          position="static"
                          color="default"
                          elevation={0}>
                    <Toolbar>
                      <Typography variant="h6" gutterBottom component="div">
                        Module indicators
                      </Typography>
                    </Toolbar>
                  </AppBar>
                  <div className={classes.contentWrapper}>
                    {stateProject.indicators && stateProject.indicators.filter(
                        function (indicator) {
                          return indicator.indicatorType === 'MODULE';
                        }).map((indicator) => (
                        <Paper elevation={0} variant="outlined"
                               className={classes.paper}>
                          <AppBar className={classes.toolbar}
                                  position="static"
                                  color="default"
                                  elevation={0}>
                            <Toolbar>
                              <Typography variant="h6" gutterBottom
                                          component="div">
                                {indicator.name}
                              </Typography>
                            </Toolbar>
                          </AppBar>
                          <div className={classes.contentWrapper}>
                            <Typography color="textSecondary">
                              {indicator.description}
                            </Typography>
                          </div>
                          <div className={classes.contentWrapper}>
                            <StyledLinearProgress
                                value={Math.round(
                                    arrSum(indicator.indicatorValues)
                                    / indicator.targetValueNumeric * 100)}
                                current={arrSum(indicator.indicatorValues)}
                                target={indicator.targetValueNumeric}/>
                          </div>
                        </Paper>
                    ))}
                  </div>
                </Paper>
              }
              {stateProject.indicators && stateProject.indicators.filter(
                  function (indicator) {
                    return indicator.indicatorType === 'OUTPUT';
                  }).length > 0 &&
                <Paper elevation={0} variant="outlined" className={classes.paper}>
                  <AppBar className={classes.toolbar}
                          position="static"
                          color="default"
                          elevation={0}>
                    <Toolbar>
                      <Typography variant="h6" gutterBottom component="div">
                        Module indicators
                      </Typography>
                    </Toolbar>
                  </AppBar>
                  <div className={classes.contentWrapper}>
                    {stateProject.indicators && stateProject.indicators.filter(
                        function (indicator) {
                          return indicator.indicatorType === 'MODULE';
                        }).map((indicator) => (
                        <Paper elevation={0} variant="outlined"
                               className={classes.paper}>
                          <AppBar className={classes.toolbar}
                                  position="static"
                                  color="default"
                                  elevation={0}>
                            <Toolbar>
                              <Typography variant="h6" gutterBottom
                                          component="div">
                                {indicator.name}
                              </Typography>
                            </Toolbar>
                          </AppBar>
                          <div className={classes.contentWrapper}>
                            <Typography color="textSecondary">
                              {indicator.description}
                            </Typography>
                          </div>
                          <div className={classes.contentWrapper}>
                            <StyledLinearProgress
                                value={Math.round(
                                    arrSum(indicator.indicatorValues)
                                    / indicator.targetValueNumeric * 100)}
                                current={arrSum(indicator.indicatorValues)}
                                target={indicator.targetValueNumeric}/>
                          </div>
                        </Paper>
                    ))}
                  </div>
                </Paper>
              }
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
  );
}

function Projects(props) {
  const { classes } = props;
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const removeProject = async (projectId, deleteType = 'SOFT') => {
    await deleteProject(projectId, deleteType);
    await fetchData();
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);

  const handleSearchTermChanged = (event) => {
    const term = event.target.value;
    setFilteredProjects(projects.filter(project => {
      let titleTerm = false;

      if (project.title) {
        titleTerm = project.title.toLowerCase().includes(term.toLowerCase());
      }

      return titleTerm;
    }));
  };

  const addProjectConfirmationHandler =
      async (title, shortTitle, projectNumber, projectObjective,
          headOfProject, partners) => {
        await createProject({
          'title': title,
          'titleShort': shortTitle,
          'projectNumber': projectNumber,
          'projectObjective': projectObjective,
          'headOfProject': headOfProject,
          'executingAgency': partners,
        });
        await fetchData();
      };

  const cancelRequest = useRef();

  const fetchData =
      async loading => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getProjects();
          const projectData = result.data;

          if (!cancelRequest.current) {
            if (projectData && Object.keys(projectData).length > 0) {
              setProjects(projectData);
              setFilteredProjects(projectData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false);
  }, []);

  const renderData = () => {
    if (isError) {
      return (
          <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
            <AddProjectDialog classes={classes} confirmationHandler={addProjectConfirmationHandler}/>
          </div>
      );
    }

    if (isLoading || !projects) {
      return (
          <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
            <CircularProgress style={{marginTop: '10%'}} color="primary" />
          </div>
      );
    }

    return (
        <>
          <div className={classes.contentWrapper} style={{width: '95%'}}>
            <TextField
                label="Search by title"
                id="project-filter"
                variant="outlined"
                size="small"
                fullWidth
                onChange={handleSearchTermChanged}
            />
          </div>
          <TableContainer style={{width: '95%'}} component={Paper} className={classes.tableContainer}>
            <Table aria-label="collapsible table">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell />
                  <TableCell>Short name</TableCell>
                  <TableCell>Number</TableCell>
                  <TableCell>Head of project</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredProjects.map((row) => (
                    <Row key={row.id} project={row} fetchData={fetchData} shouldOpen={projects.length === 1} removeHandler={removeProject}/>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <AddProjectDialog classes={classes}
                           confirmationHandler={addProjectConfirmationHandler}/>
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(Projects);