import React, {useState} from "react";
import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AuthIcon from "@material-ui/icons/Fingerprint";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { auth } from "./auth/firebase";

const styles = () => ({
  contentWrapper: {
    width: 500,
    margin: 'auto',
    marginTop: '10rem',
    marginLeft: 'auto',
    textAlign: 'center',
  },
  button: {
    margin: 'auto',
    marginTop: '2rem',
  },
  input: {
    marginTop: '2rem',
  },
  paper: {
    padding: '2rem',
  },
  loginError: {
    color: 'red',
  }
});

function Login(props) {
  const { classes } = props;

  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(false);

  const signInWithEmailAndPasswordHandler =
      (event, userValue, passwordValue) => {
        auth.signInWithEmailAndPassword(userValue, passwordValue)
        .then(() => {
          setLoginError(false);
        })
        .catch(() => {
          setLoginError(true);
        });
        event.preventDefault();
      };

  const onUserChange = (e) => {
    setUser(e.target.value);
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
      <>
        <div className={classes.contentWrapper}>
          <Paper elevation={3} className={classes.paper}>
            <AuthIcon style={{ fontSize: 80 }} />
            <Typography color="inherit" variant="h5" component="h1">
              Please log-in first!
            </Typography>
            <form className={classes.root}>
              <div>
                <TextField
                    className={classes.input}
                    fullWidth
                    id="user"
                    value={user}
                    label="User"
                    variant="outlined"
                    onChange={onUserChange}/>
              </div>
              <div>
                <TextField
                    className={classes.input}
                    fullWidth
                    id="password"
                    value={password}
                    label="Password"
                    variant="outlined"
                    type="password"
                    onChange={onPasswordChange} />
              </div>
              <div>
                <Typography className={classes.loginError} variant="caption" component="div" hidden={!loginError}>
                  Wrong credentials!
                </Typography>
              </div>
              <div>
                <Button
                    className={classes.input}
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={(event) => {signInWithEmailAndPasswordHandler(event, user, password)}}>
                  Login
                </Button>
              </div>
            </form>
          </Paper>
        </div>
      </>
  );
}

export default withStyles(styles)(Login);