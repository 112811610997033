import axios from 'axios';
import { auth } from "../components/auth/firebase";

let token = null;

async function setupAuthorizationHeaders() {
  if (!token) {
    token = await auth.currentUser.getIdToken();
  }

  return {
    Authorization: `Bearer ${token}`,
  };
}

const api = axios.create({
  baseURL: process.env.REACT_APP_GIZLIBYA_BACKEND,
  headers: {
    accept: 'application/json',
  },
});

api.interceptors.request.use(
    async config => {
      const newConfig = config;
      const newHeaders = await setupAuthorizationHeaders();
      newConfig.headers = {
        ...config.headers,
        ...newHeaders,
      };
      return newConfig;
    },
    error => {
      Promise.reject(error);
    }
);

export default api;

/* --- GET requests --- */
export async function getUsers() {
  return api.get('/users');
}

export async function getStatistics() {
  return api.get('/statistics');
}

export async function getProjectStatistics(projectId) {
  return api.get(`/project-statistics/${projectId}`);
}

export async function getProjects() {
  return api.get('/projects');
}

export async function searchProjects(searchTerm) {
  return api.get(`/projects-search?searchTerm=${searchTerm}`);
}

export async function searchMunicipalities(searchTerm) {
  return api.get(`/municipalities-search?searchTerm=${searchTerm}`);
}

export async function getActivities(state, orderBy, order) {
  return api.get(`/activities?state=${state}&orderBy=${orderBy}&order=${order}`);
}

export async function getActivity(activityId) {
  return api.get(`/activities/${activityId}`);
}

export async function getProjectActivities(state, projectId, orderBy, order) {
  return api.get(`/project-activities?state=${state}&projectId=${projectId}&orderBy=${orderBy}&order=${order}`);
}

export async function searchActivities(searchTerm) {
  return api.get(`/activities-search?searchTerm=${searchTerm}`);
}

export async function getIndicators() {
  return api.get('/indicators');
}

export async function getGoals() {
  return api.get('/goals');
}

/* --- POST requests ---*/

export async function createUser(body) {
  return api.post(`/users`, body);
}

export async function createProject(body) {
  return api.post(`/projects`, body);
}

export async function createActivity(body) {
  return api.post(`/activities`, body);
}

export async function createIndicator(body) {
  return api.post(`/indicators`, body);
}

export async function addIndicatorValue(indicatorId, body) {
  return api.post(`/indicators/${indicatorId}/value`, body);
}

/* --- PUT requests ---*/

export async function updateActivity(activityId, body) {
  return api.put(`/activities/${activityId}`, body);
}

export async function updateProjectForUser(id, body) {
  return api.put(`/user-projects/${id}`, body);
}

export async function updateProject(projectId, body) {
  return api.put(`/projects/${projectId}`, body);
}

export async function updateIndicator(indicatorId, body) {
  return api.put(`/indicators/${indicatorId}`, body);
}

export async function updateIndicatorValue(indicatorId, indicatorValueId, body) {
  return api.put(`/indicators/${indicatorId}/value/${indicatorValueId}`, body);
}

/* --- DELETE requests ---*/

export async function deleteProject(projectId, deleteType = 'SOFT') {
  return api.delete(`/projects/${projectId}?deleteType=${deleteType}`);
}

export async function deleteActivity(activityId, deleteType = 'SOFT') {
  return api.delete(`/activities/${activityId}?deleteType=${deleteType}`);
}

export async function deleteIndicator(indicatorId) {
  return api.delete(`/indicators/${indicatorId}`);
}

export async function deleteIndicatorValue(indicatorId, indicatorValueId) {
  return api.delete(`/indicators/${indicatorId}/value/${indicatorValueId}`);
}

export async function deleteUser(usersId) {
  return api.delete(`/users/${usersId}`);
}
