import React, {useState} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import TextField from "@material-ui/core/TextField";
import BasicAutocomplete from "../autocompletes/BasicAutocomplete";
import {searchProjects} from "../../utils/api";
import {
  Description,
  DescriptionList,
  DescriptionTerm
} from "../parts/Description.parts";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import {Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

function AddIndicatorDialog(props) {
  const { classes, confirmationHandler, selectedProject, action = 'create', entity } = props;
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState('');
  const [abbreviation, setAbbreviation] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [project, setProject] = useState(selectedProject);
  const [depth, setDepth] = useState(0);

  const [startValueNumeric, setStartValueNumeric] = useState(0);
  const [targetValueNumeric, setTargetValueNumeric] = useState(0);
  const [targetValueDescription, setTargetValueDescription] =
      useState('');
  const [secondaryStartValue, setSecondaryStartValue] = useState(0);
  const [secondaryTargetValue, setSecondaryTargetValue] = useState(0);
  const [secondaryTargetValueDescription, setSecondaryTargetValueDescription] =
      useState('');
  const [tertiaryStartValue, setTertiaryStartValue] = useState(0);
  const [tertiaryTargetValue, setTertiaryTargetValue] = useState(0);
  const [tertiaryTargetValueDescription, setTertiaryTargetValueDescription] =
      useState('');

  const handleClickOpen = () => {
    setOpen(true);
    setName(entity && entity.name ? entity.name : '');
    setAbbreviation(entity && entity.abbreviation ? entity.abbreviation : '');
    setDescription(entity && entity.description ? entity.description : '');
    setDepth(entity && entity.tertiaryTargetValueNumeric >= 0 ? 2 : entity && entity.secondaryTargetValueNumeric >= 0 ? 1 : 0 );
    setStartValueNumeric(entity && entity.startValueNumeric ? entity.startValueNumeric : 0);
    setTargetValueNumeric(entity && entity.targetValueNumeric ? entity.targetValueNumeric : 0);
    setTargetValueDescription(entity && entity.targetValueDescription ? entity.targetValueDescription : '');
    setSecondaryStartValue(entity && entity.secondaryStartValueNumeric ? entity.secondaryStartValueNumeric : 0);
    setSecondaryTargetValue(entity && entity.secondaryTargetValueNumeric ? entity.secondaryTargetValueNumeric : 0);
    setSecondaryTargetValueDescription(entity && entity.secondaryTargetValueDescription ? entity.secondaryTargetValueDescription : '');
    setTertiaryStartValue(entity && entity.tertiaryStartValueNumeric ? entity.tertiaryStartValueNumeric : 0);
    setTertiaryTargetValue(entity && entity.tertiaryTargetValueNumeric ? entity.tertiaryTargetValueNumeric : 0);
    setTertiaryTargetValueDescription(entity && entity.tertiaryTargetValueDescription ? entity.tertiaryTargetValueDescription : '');
  };

  const handleClose = () => {
    setOpen(false);
    setName('');
    setAbbreviation('');
    setDescription('');
    setProject(undefined);
    setStartValueNumeric(0);
    setTargetValueNumeric(0);
    setTargetValueDescription('');
    setSecondaryStartValue(0);
    setSecondaryTargetValue(0);
    setSecondaryTargetValueDescription('');
    setTertiaryStartValue(0);
    setTertiaryTargetValue(0);
    setTertiaryTargetValueDescription('');
  };

  const handleConfirm = () => {
    action === 'create' ?
        confirmationHandler(depth, name, abbreviation, description, project ? project : selectedProject,
            startValueNumeric, targetValueNumeric, targetValueDescription, secondaryStartValue,
            secondaryTargetValue, secondaryTargetValueDescription,
            tertiaryStartValue, tertiaryTargetValue,
            tertiaryTargetValueDescription) :
        confirmationHandler(entity.id, depth, name, abbreviation, description, project ? project : selectedProject,
            startValueNumeric, targetValueNumeric, targetValueDescription, secondaryStartValue,
            secondaryTargetValue, secondaryTargetValueDescription,
            tertiaryStartValue, tertiaryTargetValue,
            tertiaryTargetValueDescription);
    handleClose();
  };

  const onNameChange = (e) => {
    setName(e.target.value);
  };

  const onAbbreviationChange = (e) => {
    setAbbreviation(e.target.value);
  };

  const onDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const onProjectChange = (value) => {
    setProject(value);
  };

  function handleIndicatorDepthChange(event) {
    setDepth(event.target.value);
  }

  const onStartValueChange = (e) => {
    setStartValueNumeric(e.target.value);
  };

  const onTargetValueChange = (e) => {
    setTargetValueNumeric(e.target.value);
  };

  const onTargetValueDescriptionChange = (e) => {
    setTargetValueDescription(e.target.value);
  };

  const onSecondaryStartValueChange = (e) => {
    setSecondaryStartValue(e.target.value);
  };

  const onSecondaryTargetValueChange = (e) => {
    setSecondaryTargetValue(e.target.value);
  };

  const onSecondaryTargetValueDescriptionChange = (e) => {
    setSecondaryTargetValueDescription(e.target.value);
  };

  const onTertiaryStartValueChange = (e) => {
    setTertiaryStartValue(e.target.value);
  };

  const onTertiaryTargetValueChange = (e) => {
    setTertiaryTargetValue(e.target.value);
  };

  const onTertiaryTargetValueDescriptionChange = (e) => {
    setTertiaryTargetValueDescription(e.target.value);
  };

  return (
      <div>
        { action === 'create' &&
          <Fab
              variant="extended"
              size="medium"
              color="primary"
              aria-label="add"
              onClick={handleClickOpen}
              className={classes.fab}
              style={{position: 'fixed'}}
          >
            <AddIcon/>
            Add indicator
          </Fab>
        }
        { action === 'edit' &&
          <Tooltip title="Edit">
            <IconButton aria-label="edit"
                        onClick={handleClickOpen}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        }
        <Dialog fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{ action === 'create' ? 'Add new' : 'Edit'} indicator</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter the necessary information
            </DialogContentText>
            <TextField
                autoFocus
                margin="normal"
                id="name"
                label="Name"
                type="text"
                value={name}
                onChange={onNameChange}
                fullWidth
                variant="outlined"
            />
            <TextField
                autoFocus
                margin="normal"
                id="abbreviation"
                label="Short description"
                type="text"
                value={abbreviation}
                onChange={onAbbreviationChange}
                fullWidth
                variant="outlined"
            />
            <TextField
                autoFocus
                margin="normal"
                id="description"
                label="Description"
                type="text"
                value={description}
                onChange={onDescriptionChange}
                rows={4}
                multiline
                fullWidth
                variant="outlined"
            />
            { action !== 'edit' &&
              <BasicAutocomplete handleChange={onProjectChange} searchFct={searchProjects} currentValue={selectedProject} label={'Select project'} property={'title'}/>
            }
            <br/>
            <DescriptionList>
              <DescriptionTerm>Select indicator depth</DescriptionTerm>
              <Description>
                <Select
                    labelId="indicator-depth-select"
                    id="indicator-depth-select"
                    variant="outlined"
                    value={depth}
                    className={classes.selectEmpty}
                    onChange={handleIndicatorDepthChange}
                    style={{marginBottom: '1rem'}}
                >
                  <MenuItem value={0}>Single value</MenuItem>
                  <MenuItem value={1}>Secondary value</MenuItem>
                  <MenuItem value={2}>Secondary and tertiary value</MenuItem>
                </Select>
              </Description>
            </DescriptionList>
            <Typography variant="subtitle1" color="textPrimary">
              Primary target value
            </Typography>
            <TextField
                autoFocus
                margin="normal"
                id="startValueNumeric"
                label="Baseline value"
                type="number"
                value={startValueNumeric}
                onChange={onStartValueChange}
                fullWidth
                variant="outlined"
            />
            <TextField
                autoFocus
                margin="normal"
                id="targetValueNumeric"
                label="Target value"
                type="number"
                value={targetValueNumeric}
                onChange={onTargetValueChange}
                fullWidth
                variant="outlined"
            />
            <TextField
                autoFocus
                margin="normal"
                id="description"
                label="Value label (e.g. women)"
                type="text"
                value={targetValueDescription}
                onChange={onTargetValueDescriptionChange}
                fullWidth
                variant="outlined"
            />
            { depth > 0 &&
              <>
                <Typography variant="subtitle1" color="textPrimary">
                  Secondary target value
                </Typography>
                <TextField
                    autoFocus
                    margin="normal"
                    id="secondaryStartValue"
                    label="Secondary baseline value"
                    type="number"
                    value={secondaryStartValue}
                    onChange={onSecondaryStartValueChange}
                    fullWidth
                    variant="outlined"
                />
                <TextField
                    autoFocus
                    margin="normal"
                    id="secondaryTargetValue"
                    label="Secondary target value"
                    type="number"
                    value={secondaryTargetValue}
                    onChange={onSecondaryTargetValueChange}
                    fullWidth
                    variant="outlined"
                />
                <TextField
                    autoFocus
                    margin="normal"
                    id="description"
                    label="Secondary value label (e.g. women)"
                    type="text"
                    value={secondaryTargetValueDescription}
                    onChange={onSecondaryTargetValueDescriptionChange}
                    fullWidth
                    variant="outlined"
                />
              </>
            }
            { depth > 1 &&
            <>
              <Typography variant="subtitle1" color="textPrimary">
                Tertiary target value
              </Typography>
              <TextField
                  autoFocus
                  margin="normal"
                  id="tertiaryStartValue"
                  label="Tertiary baseline value"
                  type="number"
                  value={tertiaryStartValue}
                  onChange={onTertiaryStartValueChange}
                  fullWidth
                  variant="outlined"
              />
              <TextField
                  autoFocus
                  margin="normal"
                  id="tertiaryTargetValue"
                  label="Tertiary target value"
                  type="number"
                  value={tertiaryTargetValue}
                  onChange={onTertiaryTargetValueChange}
                  fullWidth
                  variant="outlined"
              />
              <TextField
                  autoFocus
                  margin="normal"
                  id="description"
                  label="Tertiary value label (e.g. young women)"
                  type="text"
                  value={tertiaryTargetValueDescription}
                  onChange={onTertiaryTargetValueDescriptionChange}
                  fullWidth
                  variant="outlined"
              />
            </>
            }

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              { action === 'create' ? 'Create' : 'Update'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(AddIndicatorDialog)