import React from "react";

export function formatRisk(rawType) {
  if (!rawType) {
    return null;
  }

  switch (rawType) {
    case 'LOW': return 'Low';
    case 'MEDIUM': return 'Medium';
    case 'HIGH': return 'High';
    default:
      return '';
  }
}

export function formatRiskCategory(rawType) {
  if (!rawType) {
    return null;
  }

  switch (rawType) {
    case 'POLITICAL': return 'Political risks';
    case 'SAFETY_AND_SECURITY': return 'Safety and security';
    case 'IMPLEMENTATION_RISKS': return 'Implementation risks';
    case 'SUSTAINABILITY_RISKS': return 'Sustainability risks';
    case 'CORRUPTION_RISKS': return 'Corruption risks';
    case 'OTHER': return 'Other';
    default:
      return '';
  }
}