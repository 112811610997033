import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { CssBaseline } from '@material-ui/core';
import { StylesProvider, ThemeProvider } from '@material-ui/styles';
import appTheme from "../config/appTheme";

function GlobalTheme({ children }) {
  return (
      <StylesProvider injectFirst>
        <StyledThemeProvider theme={appTheme}>
          <ThemeProvider theme={appTheme}>
            <CssBaseline />
            {children}
          </ThemeProvider>
        </StyledThemeProvider>
      </StylesProvider>
  );
}

GlobalTheme.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

export default GlobalTheme;