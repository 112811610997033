import React from "react";

export function formatActivityType(rawType) {
  if (!rawType) {
    return null;
  }

  switch (rawType) {
      case 'WORKSHOP': return 'Workshop';
      case 'TRAINING': return 'Training';
      case 'CONFERENCE': return 'Conference';
      case 'FORMAL_MEETING': return 'Formal meeting';
      case 'INFORMAL_MEETING': return 'Informal meeting';
      case 'NETWORKING': return 'Networking / Knowledge Exchange';
      case 'STUDY_TRIP': return 'Study Trip';
      case 'HANDOVER': return 'Handover of materials / equipment';
      case 'CONSTRUCTION': return 'Construction / Rehabilitation';
      case 'STUDY': return 'Study';
      case 'PUBLICATION': return 'Publication';
      case 'OTHER': return 'Other';
    default:
      return '';
  }
}

export function getInTextActivityType(rawType) {
  if (!rawType) {
    return null;
  }

  switch (rawType) {
    case 'WORKSHOP': return 'workshop';
    case 'TRAINING': return 'training';
    case 'CONFERENCE': return 'conference';
    case 'FORMAL_MEETING': return 'formal meeting';
    case 'INFORMAL_MEETING': return 'informal meeting';
    case 'NETWORKING': return 'networking / Knowledge Exchange';
    case 'STUDY_TRIP': return 'study Trip';
    case 'HANDOVER': return 'handover of materials / equipment';
    case 'CONSTRUCTION': return 'construction / Rehabilitation';
    case 'STUDY': return 'study';
    case 'PUBLICATION': return 'publication';
    case 'OTHER': return 'other';
    default:
      return '';
  }
}