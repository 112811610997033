import React, {useState} from 'react';
import {CircularProgress} from '@material-ui/core';
import {withStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import IndicatorsCluster from "./IndicatorsCluster";

const styles = (theme) => ({
  paper: {
    padding: 15,
    marginTop: 10
  },
  main: {
    flex: 1,
    padding: theme.spacing(3, 3),
    background: '#eaeff1',
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          style={{height: '100%'}}
          {...other}
      >
        {value === index && (
            <div style={{height: '100%'}}>{children}</div>
        )}
      </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function ClusterIndicators(props) {
  const { classes, prjct } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isForbidden, setIsForbidden] = useState(false);
  const [value, setValue] = useState(0);

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isForbidden) {
      return (
          <>
            Access denied!
          </>
      );
    }

    if (isLoading) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    return (
        <main className={classes.main}>
          <IndicatorsCluster indicatorType={'CLUSTER'} prjct={prjct} />
        </main>
    );
  };

  return renderData();
}

export default withStyles(styles)(ClusterIndicators);