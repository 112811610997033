import React, {useEffect, useRef} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import {
  Description,
  DescriptionList,
  DescriptionTerm
} from "../parts/Description.parts";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {getProjects} from "../../utils/api";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

function AddUserDialog(props) {
  const { classes, confirmationHandler } = props;
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [role, setRole] = React.useState('ROLE_STANDARD');
  const [project, setProject] = React.useState({ id: 0, title: 'Global'});
  const [availableProjects, setAvailableProjects] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const cancelRequest = useRef();

  const fetchData =
      async () => {
        try {
          const result = await getProjects();
          const projectData = result.data;

          if (!cancelRequest.current) {
            if (projectData && Object.keys(projectData).length > 0) {
              setAvailableProjects(projectData);
            }
          }
        } catch (error) {
        }
      };

  useEffect(() => {
    fetchData();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setName('');
    setEmail('');
    setProject('0');
    setRole('ROLE_STANDARD');
  };

  const handleConfirm = () => {
    confirmationHandler(name, email, role, project);
    handleClose();
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const handleProjectChange = (event) => {
    setProject(availableProjects.find(p => p.id === event.target.value));
  };

  return (
      <div>
        <Fab
            variant="extended"
            size="medium"
            color="primary"
            aria-label="add"
            onClick={handleClickOpen}
            className={classes.fab}
            style={{position: 'fixed', marginBottom: '1rem'}}
        >
          <AddIcon/>
          Add user
        </Fab>
        <Dialog fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Add user</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the details of the new user
            </DialogContentText>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    autoFocus
                    margin="normal"
                    id="name"
                    label="Name"
                    type="text"
                    value={name}
                    variant="outlined"
                    onChange={handleNameChange}
                    fullWidth
                />
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    autoFocus
                    margin="normal"
                    id="email"
                    label="Email"
                    type="text"
                    value={email}
                    variant="outlined"
                    onChange={handleEmailChange}
                    fullWidth
                />
              </FormControl>
            </div>
            <DescriptionList>
              <DescriptionTerm>Project</DescriptionTerm>
              <Description>
                <Select
                    labelId="project-select"
                    id="project-select"
                    value={project ? project.id + '' : '0'}
                    variant="outlined"
                    className={classes.selectEmpty}
                    onChange={handleProjectChange}
                >
                  <MenuItem value={'0'}>Cluster</MenuItem>
                  {availableProjects.length > 0 && availableProjects.map((p) => (
                      <MenuItem value={p.id}>{p.title}</MenuItem>
                  ))}
                </Select>
              </Description>
            </DescriptionList>
            <DescriptionList>
              <DescriptionTerm>Type</DescriptionTerm>
              <Description>
                <Select
                    labelId="role-select"
                    id="role-select"
                    value={role}
                    variant="outlined"
                    className={classes.selectEmpty}
                    onChange={handleRoleChange}
                >
                  <MenuItem value={'ROLE_SUPER'}>Super User (Admin)</MenuItem>
                  <MenuItem value={'ROLE_STANDARD'}>Standard</MenuItem>
                  <MenuItem value={'ROLE_READER'}>Read-only</MenuItem>
                </Select>
              </Description>
            </DescriptionList>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(AddUserDialog)