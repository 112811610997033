import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {AppBar, Tab, Tabs} from "@material-ui/core";
import IndicatorsRisk from "./IndicatorsRisk";
import {useHistory} from "react-router-dom";

const styles = () => ({
  paper: {
    maxWidth: '80%',
    minWidth: '70%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          style={{height: '100%'}}
          {...other}
      >
        {value === index && (
            <div style={{height: '100%'}}>{children}</div>
        )}
      </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function RiskIndicators(props) {
  const { classes } = props;

  const [value, setValue] = useState(0);
  let history = useHistory();

  const handleTabChange = (event, newValue) => {
    history.push(`/risk-indicators/${newValue}`);
    setValue(newValue);
  };

  const renderData = () => {
    return (
        <>
          <AppBar
              component="div"
              className={classes.appBar}
              color="primary"
              position="static"
              elevation={5}
          >
            <Tabs value={value} onChange={handleTabChange}>
              <Tab textColor="inherit" label={'Political'} {...a11yProps(0)}/>
              <Tab textColor="inherit" label={'Safety and security'} {...a11yProps(1)}/>
              <Tab textColor="inherit" label={'Implementation risks'} {...a11yProps(2)}/>
              <Tab textColor="inherit" label={'Sustainability risks'} {...a11yProps(3)}/>
              <Tab textColor="inherit" label={'Corruption risks'} {...a11yProps(4)}/>
              <Tab textColor="inherit" label={'Other'} {...a11yProps(5)}/>
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <main className={classes.main}>
              <IndicatorsRisk indicatorType={'RISK'} riskCategory={'POLITICAL'} />
            </main>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <main className={classes.main}>
              <IndicatorsRisk indicatorType={'RISK'} riskCategory={'SAFETY_AND_SECURITY'} />
            </main>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <main className={classes.main}>
              <IndicatorsRisk indicatorType={'RISK'} riskCategory={'IMPLEMENTATION_RISKS'} />
            </main>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <main className={classes.main}>
              <IndicatorsRisk indicatorType={'RISK'} riskCategory={'SUSTAINABILITY_RISKS'} />
            </main>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <main className={classes.main}>
              <IndicatorsRisk indicatorType={'RISK'} riskCategory={'CORRUPTION_RISKS'} />
            </main>
          </TabPanel>
          <TabPanel value={value} index={5}>
            <main className={classes.main}>
              <IndicatorsRisk indicatorType={'RISK'} riskCategory={'OTHER'} />
            </main>
          </TabPanel>
        </>
    );
  };

  return renderData();
}

RiskIndicators.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RiskIndicators);
