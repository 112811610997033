import React, {useContext, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Collapse from '@material-ui/core/Collapse';
import GridList from '@material-ui/core/GridList';
import IconButton from '@material-ui/core/IconButton';
import {
  addIndicatorValue,
  createActivity,
  deleteActivity, deleteIndicatorValue,
  getIndicators,
  getProjectActivities,
  searchMunicipalities,
  searchProjects,
  updateActivity, updateIndicatorValue
} from "../../utils/api";
import {CircularProgress, TableSortLabel} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Paper from '@material-ui/core/Paper';
import {formatTime} from '../../utils/timeHelper';
import Tooltip from "@material-ui/core/Tooltip";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import AddActivityDialog from "../dialogs/AddActivityDialog";
import {truncate} from "../../utils/stringHelper";
import ConfirmActionDialog from "../dialogs/ConfirmActionDialog";
import {
  Description,
  DescriptionList,
  DescriptionTerm
} from "../parts/Description.parts";
import {formatActivityType} from "../../utils/activityTypeHelper";
import EditableInput from "../input/EditableInput";
import BasicAutocomplete from "../autocompletes/BasicAutocomplete";
import {Autocomplete} from "@material-ui/lab";
import AddIndicatorWithValueDialog
  from "../dialogs/AddIndicatorWithValueDialog";
import AddIndicatorValueDialog from "../dialogs/AddIndicatorValueDialog";
import AddClusterIndicatorValueDialog
  from "../dialogs/AddClusterIndicatorValueDialog";
import {UserContext} from "../auth/UserProvider";

const styles = () => ({
  contentWrapper: {
    margin: 'auto'
  },
  tableContainer: {
    margin: 'auto',
    marginTop: '2rem',
    marginLeft: '2rem',
    overflow: 'hidden',
  },
  tableHead: {
    background: '#F5F5F5',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  gridList: {
    paddingBottom: '1rem',
  },
  paper: {
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
});

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

function Row(props) {
  let { activity, fetchData, shouldOpen, removeHandler, keyId, indicators } = props;
  const [open, setOpen] = useState(shouldOpen);
  const classes = useRowStyles();
  const cancelRequest = useRef();
  const [stateActivity, setStateActivity] = useState(activity);
  const [activityBuffer, setActivityBuffer] = useState(activity);
  const [specialProject, setSpecialProject] = useState(0);

  const updateActivityDataAndDispatch = async (field, value) => {
    try {
      setActivityBuffer({
        ...activityBuffer,
        [field]: value,
      });
      const { data: activityData } = await updateActivity(
          activity.id,
          { [field]: value }
      );

      if (!cancelRequest.current) {
        setStateActivity(activityData);
      }
    } catch (error) {
      setActivityBuffer(activityBuffer);
    }
  };

  const indicatorValueConfirmationHandler =
      async (indicator, description, activity, comment, numericValue, secondaryNumericValue, tertiaryNumericValue) => {
        await addIndicatorValue(indicator.id, {
          'description': description,
          'activity': activity,
          'comment': comment,
          'numericValue': +numericValue,
          'secondaryNumericValue': +secondaryNumericValue,
          'tertiaryNumericValue': +tertiaryNumericValue
        }).then(( () => {
          let matchedIndicator = stateActivity.assignedIndicators ? stateActivity.assignedIndicators.find(i => i.id === indicator.id) : undefined;

          if (matchedIndicator !== undefined) {
            matchedIndicator.indicatorValues.push({
              'description': description,
              'activity': activity,
              'comment': comment,
              'numericValue': +numericValue,
              'secondaryNumericValue': +secondaryNumericValue,
              'tertiaryNumericValue': +tertiaryNumericValue
            })
          } else {
            indicator.indicatorValues.push({
              'description': description,
              'activity': activity,
              'comment': comment,
              'numericValue': +numericValue,
              'secondaryNumericValue': +secondaryNumericValue,
              'tertiaryNumericValue': +tertiaryNumericValue
            })

            stateActivity.assignedIndicators = [
              indicator
            ];
          }
        }))

        await fetchData()
      };

  const clusterIndicatorValueConfirmationHandler =
      async (indicator, description, attributionDate, activity, fct, females, males, value) => {
        let body = {
          'description': description,
          'activity': activity,
          'attributionDate': attributionDate,
        };

        if (fct && fct !== '') {
          body.fct = fct;
        }

        if (females >= 0) {
          body.nrFemales = +females;
          body.nrMales = 0;
        }

        if (males >= 0) {
          body.nrMales = +males;
          if (females === undefined) {
            body.nrFemales = 0;
          }
        }

        if (+value > 0) {
          body.numericValue = +value;
        } else {
          body.numericValue = 0;
        }

        await addIndicatorValue(indicator.id, body).then(( () => {
          let matchedIndicator = stateActivity.assignedIndicators ? stateActivity.assignedIndicators.find(i => i.id === indicator.id) : undefined;
          let body = {
            'description': description,
            'activity': activity,
            'attributionDate': attributionDate,
          };

          if (fct && fct !== '') {
            body.fct = fct;
          }

          if (females >= 0) {
            body.nrFemales = +females;
            body.nrMales = 0;
          }

          if (males >= 0) {
            body.nrMales = +males;
            if (females === undefined) {
              body.nrFemales = 0;
            }
          }

          if (+value > 0) {
            body.numericValue = +value;
          } else {
            body.numericValue = 0;
          }

          if (matchedIndicator !== undefined) {
            matchedIndicator.indicatorValues.push(body)
          } else {
            indicator.indicatorValues.push(body);
            if (stateActivity.assignedIndicators && stateActivity.assignedIndicators.length > 0) {
              stateActivity.assignedIndicators.push(indicator);
            } else {
              stateActivity.assignedIndicators = [
                indicator
              ];
            }
          }
        }))

        await fetchData()
      };

  const handleActivityTypeChange = async (event) => {
    await updateActivityDataAndDispatch('activityType', event.target.value);
    await fetchData();
  };

  const handleSpecialProjectChange = async (event) => {
    setSpecialProject(event.target.value);
  };

  const removeIndicatorValue = async (indicatorId, indicatorValueId) => {
    await deleteIndicatorValue(indicatorId, indicatorValueId).then(( () => {
      let matchedIndicator = stateActivity.assignedIndicators ? stateActivity.assignedIndicators.find(i => i.id === indicatorId) : undefined;

      if (matchedIndicator !== undefined) {
        matchedIndicator.indicatorValues = matchedIndicator.indicatorValues
        .filter(
            iv => iv.id !== indicatorValueId &&
                iv.activity && iv.activity.id === iv.id);
      }
    }));
    await fetchData();
  };

  const confirmIndicatorValueUpdate =
      async (indicatorValueId, indicatorId, description, activity, comment,
          numericValue, secondaryNumericValue, tertiaryNumericValue) => {

        let body = {
          'description': description,
          'activity': activity,
          'comment': comment,
          'numericValue': +numericValue,
          'secondaryNumericValue': +secondaryNumericValue,
          'tertiaryNumericValue': +tertiaryNumericValue
        };

        await updateIndicatorValueDataAndDispatch(indicatorId, indicatorValueId, body).then(( () => {
          let matchedIndicator = stateActivity.assignedIndicators ? stateActivity.assignedIndicators.find(i => i.id === indicatorId) : undefined;

          if (matchedIndicator !== undefined) {
            let matchedValueIdx = matchedIndicator.indicatorValues.findIndex(iv => iv.id === indicatorValueId);

            if (matchedValueIdx !== -1) {
              body.creationDate = matchedIndicator.indicatorValues[matchedValueIdx].creationDate;
              matchedIndicator.indicatorValues[matchedValueIdx] = body;
            }
          }
        }));
        await fetchData();
      };

  const confirmClusterIndicatorValueUpdate =
      async (indicatorValueId, indicatorId, description, attributionDate, activity, fct, females, males, value) => {
        let body = {
          'description': description,
          'activity': activity,
          'attributionDate': attributionDate,
        };

        if (fct && fct !== '') {
          body.fct = fct;
        }

        if (females >= 0) {
          body.nrFemales = +females;
          body.nrMales = 0;
        }

        if (males >= 0) {
          body.nrMales = +males;
          if (females === undefined) {
            body.nrFemales = 0;
          }
        }

        if (value > 0) {
          body.numericValue = +value;
        } else {
          body.numericValue = 0;
        }

        await updateIndicatorValueDataAndDispatch(indicatorId, indicatorValueId, body).then(( () => {
          let matchedIndicator = stateActivity.assignedIndicators ? stateActivity.assignedIndicators.find(i => i.id === indicatorId) : undefined;

          if (matchedIndicator !== undefined) {
            let matchedValueIdx = matchedIndicator.indicatorValues.findIndex(iv => iv.id === indicatorValueId);

            if (matchedValueIdx !== -1) {

              matchedIndicator.indicatorValues[matchedValueIdx] = body;
            }
          }
        }));
        await fetchData();
      };

  const updateIndicatorValueDataAndDispatch = async (indicatorId, indicatorValueId, fields) => {
    try {
      await updateIndicatorValue(
          indicatorId,
          indicatorValueId,
          fields
      );
    } catch (error) {
    }
  };

  return (
      <React.Fragment>
        <TableRow key={`${keyId}-head`} className={classes.root}>
          <TableCell style={{ paddingBottom: 0 }} colSpan={9}>
            <strong>{stateActivity.name}</strong>
          </TableCell>
        </TableRow>
        <TableRow key={keyId} className={classes.root}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{formatActivityType(stateActivity.activityType)}</TableCell>
          <TableCell>{stateActivity.project && stateActivity.project.titleShort ? stateActivity.project.titleShort : ''}</TableCell>
          <TableCell>{formatTime(stateActivity.startDate, 'dd.LL.yyyy')}</TableCell>
          <TableCell>{formatTime(stateActivity.endDate, 'dd.LL.yyyy')}</TableCell>
          <TableCell>{stateActivity.municipality ? stateActivity.municipality.englishName + ' (' + stateActivity.municipality.arabicName + ')' : ''}</TableCell>
          <TableCell>
            {stateActivity.indirectMunicipalities.map((m) => (
                <Chip size="small" style={{marginBottom: '0.2rem', marginRight: '0.8rem'}} label={m.englishName  + ' (' + m.arabicName + ')'} />
            ))}
          </TableCell>
          <TableCell>
            {stateActivity.assignedIndicators && stateActivity.assignedIndicators.map((indicator, idx) => (
                <HtmlTooltip
                    key={idx}
                    title={
                      <React.Fragment>
                        <Typography color="inherit">{indicator.name}</Typography>
                        {indicator.description}
                      </React.Fragment>
                    }
                >
                  <Chip size="small" style={{marginBottom: '0.2rem', marginRight: '0.8rem'}} label={indicator.name ? truncate(indicator.name, 30) : ''} />
                </HtmlTooltip>
            ))}
          </TableCell>
          <TableCell width={'10%'}>
            <ConfirmActionDialog action={'delete'} confirmationHandler={() => removeHandler(stateActivity.id)}/>
          </TableCell>
        </TableRow>
        <TableRow key={`${keyId}-expanded`} >
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
            <Collapse style={{marginBottom: '1rem'}} in={open} timeout="auto" unmountOnExit>
              <Paper elevation={0} variant="outlined" className={classes.paper}>
                <AppBar className={classes.toolbar}
                        position="static"
                        color="default"
                        elevation={0}>
                  <Toolbar>
                    <Typography variant="h6" gutterBottom component="div">
                      General information
                    </Typography>
                  </Toolbar>
                </AppBar>
                <div className={classes.contentWrapper}>
                  <DescriptionList>
                    <DescriptionTerm>Type</DescriptionTerm>
                    <Description>
                      <Select
                          labelId="activity-type-select"
                          id="activity-type-select"
                          variant="outlined"
                          value={stateActivity.activityType}
                          className={classes.selectEmpty}
                          onChange={handleActivityTypeChange}
                      >
                        <MenuItem value={'WORKSHOP'}>Workshop</MenuItem>
                        <MenuItem value={'TRAINING'}>Training</MenuItem>
                        <MenuItem value={'CONFERENCE'}>Conference</MenuItem>
                        <MenuItem value={'FORMAL_MEETING'}>Formal meeting</MenuItem>
                        <MenuItem value={'INFORMAL_MEETING'}>Informal meeting</MenuItem>
                        <MenuItem value={'NETWORKING'}>Networking / Knowledge Exchange</MenuItem>
                        <MenuItem value={'STUDY_TRIP'}>Study Trip</MenuItem>
                        <MenuItem value={'HANDOVER'}>Handover of materials / equipment</MenuItem>
                        <MenuItem value={'CONSTRUCTION'}>Construction / Rehabilitation</MenuItem>
                        <MenuItem value={'STUDY'}>Study</MenuItem>
                        <MenuItem value={'PUBLICATION'}>Publication</MenuItem>
                        <MenuItem value={'OTHER'}>Other</MenuItem>
                      </Select>
                    </Description>
                  </DescriptionList><br/>
                  <GridList cellHeight={'auto'} className={classes.gridList} cols={2}>
                    <DescriptionList>
                      <DescriptionTerm>Name</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="name"
                            value={stateActivity.name}
                            disabled={false}
                            fullWidth={true}
                            size={'large'}
                            onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Allocated days</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="daysAllocated"
                            value={stateActivity.daysAllocated}
                            type="number"
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Start date</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="startDate"
                            type="date"
                            value={formatTime(stateActivity.startDate, 'dd.LL.yyyy')}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>End date</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="endDate"
                            type="date"
                            value={formatTime(stateActivity.endDate, 'dd.LL.yyyy')}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <Description>
                        <BasicAutocomplete handleChange={(value) => updateActivityDataAndDispatch('project', value)} searchFct={searchProjects} currentValue={stateActivity.project} label={'Project'} property={'title'}/>
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>External links</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="externalLink"
                            value={stateActivity.externalLink}
                            disabled={false}
                            fullWidth={true}
                            size={'large'}
                            onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                  </GridList>
                  <GridList cellHeight={'auto'} className={classes.gridList} cols={2}>
                    <DescriptionList>
                      <DescriptionTerm>Comment</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="comment"
                            value={stateActivity.comment}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                            multiline
                            rows={4}
                        />
                      </Description>
                    </DescriptionList>
                  </GridList>
                  <GridList cellHeight={'auto'} className={classes.gridList}
                            cols={2}>
                    <DescriptionList>
                      <DescriptionTerm>Created by</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="createdByEmail"
                            saveOnEnterEnabled={false}
                            value={stateActivity.createdByEmail}
                            disabled
                            fullWidth={true}
                        />
                      </Description>
                    </DescriptionList>
                  </GridList>
                </div>
              </Paper>
              <Paper elevation={0} variant="outlined" className={classes.paper}>
                <AppBar className={classes.toolbar}
                        position="static"
                        color="default"
                        elevation={0}>
                  <Toolbar>
                    <Typography variant="h6" gutterBottom component="div">
                      Location information
                    </Typography>
                  </Toolbar>
                </AppBar>
                <div className={classes.contentWrapper}>
                  <GridList cellHeight={'auto'} className={classes.gridList} cols={2}>
                    <DescriptionList>
                      <Description>
                        <BasicAutocomplete handleChange={(value) => updateActivityDataAndDispatch('municipality', value)} searchFct={searchMunicipalities} currentValue={stateActivity.municipality} label={'Municipality'} property={'englishName'} scndProperty={'arabicName'}/>
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Location</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="location"
                            value={stateActivity.location}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <BasicAutocomplete handleChange={(value) => updateActivityDataAndDispatch('indirectMunicipalities', value)} searchFct={searchMunicipalities} currentValue={stateActivity.indirectMunicipalities} label={'This activity includes people from/has implications for...'} property={'englishName'} scndProperty={'arabicName'} multiple={true}/>
                    </DescriptionList>
                  </GridList>
                </div>
              </Paper>
              <Paper elevation={0} variant="outlined" className={classes.paper}>
                <AppBar className={classes.toolbar}
                        position="static"
                        color="default"
                        elevation={0}>
                  <Toolbar>
                    <Typography variant="h6" gutterBottom component="div">
                      Participant numbers
                    </Typography>
                  </Toolbar>
                </AppBar>
                <div className={classes.contentWrapper}>
                  <GridList cellHeight={'auto'} className={classes.gridList} cols={2}>
                    <DescriptionList>
                      <DescriptionTerm>Number of participants</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="numberParticipants"
                            value={stateActivity.numberParticipants}
                            type="number"
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Number of females</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="numberFemaleParticipants"
                            value={stateActivity.numberFemaleParticipants}
                            type="number"
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Select additional fields</DescriptionTerm>
                      <Description>
                        <Select
                            labelId="activity-type-select"
                            id="activity-type-select"
                            variant="outlined"
                            value={specialProject}
                            className={classes.selectEmpty}
                            onChange={handleSpecialProjectChange}
                            style={{marginBottom: '1rem'}}
                        >
                          <MenuItem value={0}>None</MenuItem>
                          <MenuItem value={1}>EU-Disaggregation</MenuItem>
                          <MenuItem value={2}>Special initiative (SI)</MenuItem>
                        </Select>
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                    </DescriptionList>
                    { specialProject === 1 &&
                        <GridList cellHeight={'auto'} className={classes.gridList} cols={2}>
                          <DescriptionList>
                            <DescriptionTerm>Number of males</DescriptionTerm>
                            <Description>
                              <EditableInput
                                  name="numberMaleParticipants"
                                  value={stateActivity.numberMaleParticipants}
                                  type="number"
                                  disabled={false}
                                  fullWidth={true}
                                  onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                              />
                            </Description>
                          </DescriptionList>
                          <DescriptionList>
                            <DescriptionTerm>Number of IDPs</DescriptionTerm>
                            <Description>
                              <EditableInput
                                  name="numberIdpParticipants"
                                  value={stateActivity.numberIdpParticipants}
                                  type="number"
                                  disabled={false}
                                  fullWidth={true}
                                  onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                              />
                            </Description>
                          </DescriptionList>
                          <DescriptionList>
                            <DescriptionTerm>Number of asylum seekers</DescriptionTerm>
                            <Description>
                              <EditableInput
                                  name="numberAsylumSeekers"
                                  value={stateActivity.numberAsylumSeekers}
                                  type="number"
                                  disabled={false}
                                  fullWidth={true}
                                  onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                              />
                            </Description>
                          </DescriptionList>
                          <DescriptionList>
                            <DescriptionTerm>Number of refugees</DescriptionTerm>
                            <Description>
                              <EditableInput
                                  name="numberRefugees"
                                  value={stateActivity.numberRefugees}
                                  type="number"
                                  disabled={false}
                                  fullWidth={true}
                                  onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                              />
                            </Description>
                          </DescriptionList>
                          <DescriptionList>
                            <DescriptionTerm>Number of returnees</DescriptionTerm>
                            <Description>
                              <EditableInput
                                  name="numberReturnees"
                                  value={stateActivity.numberReturnees}
                                  type="number"
                                  disabled={false}
                                  fullWidth={true}
                                  onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                              />
                            </Description>
                          </DescriptionList>
                          <DescriptionList>
                            <DescriptionTerm>Number of PwDs</DescriptionTerm>
                            <Description>
                              <EditableInput
                                  name="numberDisabled"
                                  value={stateActivity.numberDisabled}
                                  type="number"
                                  disabled={false}
                                  fullWidth={true}
                                  onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                              />
                            </Description>
                          </DescriptionList>
                          <DescriptionList>
                            <DescriptionTerm>Age group (0-14)</DescriptionTerm>
                            <Description>
                              <EditableInput
                                  name="numberKids"
                                  value={stateActivity.numberKids}
                                  type="number"
                                  disabled={false}
                                  fullWidth={true}
                                  onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                              />
                            </Description>
                          </DescriptionList>
                          <DescriptionList>
                            <DescriptionTerm>Age group (15-24)</DescriptionTerm>
                            <Description>
                              <EditableInput
                                  name="numberAdolescents"
                                  value={stateActivity.numberAdolescents}
                                  type="number"
                                  disabled={false}
                                  fullWidth={true}
                                  onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                              />
                            </Description>
                          </DescriptionList>
                          <DescriptionList>
                            <DescriptionTerm>Age group (25-35)</DescriptionTerm>
                            <Description>
                              <EditableInput
                                  name="numberYoungAdults"
                                  value={stateActivity.numberYoungAdults}
                                  type="number"
                                  disabled={false}
                                  fullWidth={true}
                                  onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                              />
                            </Description>
                          </DescriptionList>
                          <DescriptionList>
                            <DescriptionTerm>Age group (35-65)</DescriptionTerm>
                            <Description>
                              <EditableInput
                                  name="numberAdults"
                                  value={stateActivity.numberAdults}
                                  type="number"
                                  disabled={false}
                                  fullWidth={true}
                                  onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                              />
                            </Description>
                          </DescriptionList>
                          <DescriptionList>
                            <DescriptionTerm>Age group (65+)</DescriptionTerm>
                            <Description>
                              <EditableInput
                                  name="numberElderly"
                                  value={stateActivity.numberElderly}
                                  type="number"
                                  disabled={false}
                                  fullWidth={true}
                                  onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                              />
                            </Description>
                          </DescriptionList>
                        </GridList>
                    }
                    { specialProject === 2 &&
                        <GridList cellHeight={'auto'} className={classes.gridList} cols={2}>
                          <DescriptionList>
                            <DescriptionTerm>Age group (0-24)</DescriptionTerm>
                            <Description>
                              <EditableInput
                                  name="numberSiYoung"
                                  value={stateActivity.numberSiYoung}
                                  type="number"
                                  disabled={false}
                                  fullWidth={true}
                                  onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                              />
                            </Description>
                          </DescriptionList>
                          <DescriptionList>
                            <DescriptionTerm>Age group (25-35)</DescriptionTerm>
                            <Description>
                              <EditableInput
                                  name="numberSiAdults"
                                  value={stateActivity.numberSiAdults}
                                  type="number"
                                  disabled={false}
                                  fullWidth={true}
                                  onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                              />
                            </Description>
                          </DescriptionList>
                        </GridList>
                    }
                  </GridList>
                </div>
              </Paper>
              <Paper elevation={0} variant="outlined" className={classes.paper}>
                <AppBar className={classes.toolbar}
                        position="static"
                        color="default"
                        elevation={0}>
                  <Toolbar>
                    <Typography variant="h6" gutterBottom component="div">
                      Indicator attribution
                    </Typography>
                  </Toolbar>
                </AppBar>
                <div className={classes.contentWrapper}>
                  { stateActivity.assignedIndicators && stateActivity.assignedIndicators.length === 0 &&
                  <div>There are no assigned indicators</div>
                  }
                  {stateActivity.assignedIndicators
                  && stateActivity.assignedIndicators.length > 0 &&
                  <Typography variant="subtitle1" component="div">
                    The activity has contributed to the following indicators
                  </Typography>
                  }
                  { stateActivity.assignedIndicators && stateActivity.assignedIndicators.length > 0 && stateActivity.assignedIndicators.map((indicator) => (
                      <Paper elevation={0} variant="outlined" className={classes.paper}>
                        <AppBar className={classes.toolbar}
                                position="static"
                                color="default"
                                elevation={0}>
                          <Toolbar>
                            <Typography variant="h6" gutterBottom component="div">
                              {indicator.name}
                            </Typography>
                          </Toolbar>
                        </AppBar>
                        <div className={classes.contentWrapper}>
                          {indicator.description}
                        </div>
                        <Table aria-label="collapsible table">
                          <TableHead>
                            <TableRow>
                              <TableCell width={'25%'}>Description</TableCell>
                              <TableCell width={'15%'}>Date</TableCell>
                              <TableCell width={'30%'}>Value</TableCell>
                              <TableCell width={'10%'}>Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {indicator.indicatorValues
                            && indicator.indicatorValues.length > 0
                            && indicator.indicatorValues.filter(
                                indicatorValue => indicatorValue.activity &&
                                    indicatorValue.activity.id === stateActivity.id)
                            .map((indicatorValue) => (
                                <TableRow>
                                  <TableCell>{indicatorValue.description}</TableCell>
                                  <TableCell>{formatTime(indicator.indicatorType !== 'CLUSTER' ? indicatorValue.creationDate : indicatorValue.attributionDate, 'dd.LL.yyyy')}</TableCell>
                                  { indicator.indicatorType !== 'CLUSTER' &&
                                    <TableCell><strong>{indicator.targetValueDescription} {indicatorValue.numericValue}</strong> {indicatorValue.secondaryNumericValue ? `/ ${indicator.secondaryTargetValueDescription} ${indicatorValue.secondaryNumericValue}` : ''} {indicatorValue.tertiaryNumericValue ? `/ ${indicator.tertiaryTargetValueDescription} ${indicatorValue.tertiaryNumericValue}` : ''}</TableCell>
                                  }
                                  { indicator.indicatorType === 'CLUSTER' &&
                                    <TableCell>{!indicator.disaggregateGender ? indicatorValue.numericValue : indicator.disaggregateFunction ? `Function: ${indicatorValue.fct} / Females: ${indicatorValue.nrFemales} / Males: ${indicatorValue.nrMales}` : `Females: ${indicatorValue.nrFemales} / Males: ${indicatorValue.nrMales}`}</TableCell>
                                  }
                                  <TableCell width={'10%'}>
                                    <div style={{display: 'flex'}} >
                                      { indicator.indicatorType !== 'CLUSTER' &&
                                      <AddIndicatorValueDialog action={'edit'} indicator={indicator} entity={indicatorValue} confirmationHandler={confirmIndicatorValueUpdate} disableActivityUpdate />
                                      }
                                      { indicator.indicatorType === 'CLUSTER' &&
                                      <AddClusterIndicatorValueDialog action={'edit'} indicator={indicator} entity={indicatorValue} confirmationHandler={confirmClusterIndicatorValueUpdate} disableActivityUpdate />
                                      }
                                      <ConfirmActionDialog confirmationHandler={() => removeIndicatorValue(indicator.id, indicatorValue.id)}/>
                                    </div>
                                  </TableCell>
                                </TableRow>
                            ))
                            }
                          </TableBody>
                        </Table>
                      </Paper>
                  ))
                  }
                  <AddIndicatorWithValueDialog activity={stateActivity} confirmationHandler={indicatorValueConfirmationHandler} clusterConfirmationHandler={clusterIndicatorValueConfirmationHandler} />
                  <br/>
                  <GridList cellHeight={'auto'} className={classes.gridList}
                            cols={2}>
                    <DescriptionList>
                      <Typography variant="subtitle1" gutterBottom component="div">
                        <strong>Indirect attribution</strong>
                      </Typography>
                      <Autocomplete
                          multiple
                          id="indicator-multi-select"
                          options={indicators.sort(function (a, b) {
                            if (a.indicatorType > b.indicatorType) {
                              return 1;
                            } else if (a.indicatorType < b.indicatorType) {
                              return -1;
                            }
                            let name1 = a.name.toUpperCase();
                            let name2 = b.name.toUpperCase();
                            return (name1 < name2) ? -1 : (name1 > name2) ? 1
                                : 0;
                          })}
                          groupBy={(option) => option.indicatorType}
                          getOptionLabel={(option) => `${option.name}`}
                          value={stateActivity.indicators}
                          onChange={(event,
                              value) => updateActivityDataAndDispatch(
                              'indicators', value)}
                          renderInput={(params) => (
                              <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Indicators this activity indirectly contributes to..."
                              />
                          )}
                      />
                    </DescriptionList>
                  </GridList>
                </div>
              </Paper>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
  );
}

Row.propTypes = {
  activity: PropTypes.shape({
    activityType: PropTypes.string.isRequired,
  }).isRequired,
};

function ProjectActivities(props) {
  const { classes, state, prjct } = props;

  const authState = useContext(UserContext);

  const removeActivity = async (activityId, deleteType = 'SOFT') => {
    await deleteActivity(activityId, deleteType);
    await fetchData();
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [activities, setActivities] = useState([]);
  const [indicators, setIndicators] = useState([]);
  const [filteredActivities, setFilteredActivities] = useState([]);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('startDate');
  const [searchTerm, setSearchTerm] = React.useState('');

  const handleSearchTermChanged = (event) => {
    const term = event.target.value;
    setSearchTerm(event.target.value);

    setFilteredActivities(getFilteredActivitiesBySearchTerm(term, activities));
  };

  const getFilteredActivitiesBySearchTerm = (term, acts) => {
    return acts.filter(activity => {
      let nameTerm = false;
      let typeTerm = false;
      let dateTerm = false;
      let endDateTerm = false;
      let locationTerm = false;
      let indirectLocationTerm = false;
      let outputsTerm = false;
      let projectTerm = false;
      let createdByTerm = false;

      if (activity.name) {
        nameTerm = activity.name.toLowerCase().includes(term.toLowerCase());
      }
      if (activity.activityType) {
        typeTerm = formatActivityType(activity.activityType).toLowerCase().includes(term.toLowerCase());
      }
      if (activity.startDate) {
        dateTerm = formatTime(activity.startDate).includes(term.toLowerCase());
      }
      if (activity.endDate) {
        endDateTerm = formatTime(activity.endDate).includes(term.toLowerCase());
      }
      if (activity.municipality) {
        locationTerm =
            activity.municipality.englishName.toLowerCase().includes(term.toLowerCase());
      }
      if (activity.indirectMunicipalities.length > 0) {
        indirectLocationTerm = activity.indirectMunicipalities.filter(m => m.englishName.toLowerCase().includes(term.toLowerCase())).length > 0;
      }
      if (activity.project && activity.project.titleShort) {
        projectTerm = activity.project.titleShort.toLowerCase().includes(term.toLowerCase());
      }
      if (activity.createdByEmail) {
        createdByTerm = activity.createdByEmail.toLowerCase().includes(term.toLowerCase());
      }

      return nameTerm || typeTerm || dateTerm || endDateTerm || locationTerm || outputsTerm || projectTerm || indirectLocationTerm || createdByTerm;
    })
  }

  const addActivityConfirmationHandler =
      async (values) => {
        await createActivity({
          'activityType': values.activityType,
          'name': values.name,
          'daysAllocated': +values.daysAllocated,
          'startDate': values.startDate,
          'endDate': values.endDate,
          'municipality': values.municipality,
          'project': values.project,
        });
        await fetchData();
      };

  const cancelRequest = useRef();

  const fetchData =
      async loading => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getProjectActivities(state, prjct.id, orderBy, order);
          const activityData = result.data;

          if (!cancelRequest.current) {
            if (activityData && Object.keys(activityData).length > 0) {
              setActivities(activityData);
              setFilteredActivities(getFilteredActivitiesBySearchTerm(searchTerm, activityData));
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  const fetchIndicators =
      async loading => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getIndicators();
          const indicatorData = result.data;

          if (!cancelRequest.current) {
            if (indicatorData && Object.keys(indicatorData).length > 0) {
              setIndicators(indicatorData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false);
    fetchIndicators(false);
  }, [orderBy, order]);

  const renderData = () => {
    if (isError) {
      return (
          <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
            { state === 'ALL' &&
              <AddActivityDialog classes={classes} confirmationHandler={addActivityConfirmationHandler}/>
            }
          </div>
      );
    }

    if (isLoading || !activities) {
      return (
          <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
            <CircularProgress style={{marginTop: '10%'}} color="primary" />
          </div>
      );
    }

    const sortHandler = (property) => () => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };

    const headCells = [
      { id: 'name', label: 'Name' },
      { id: 'activityType', label: 'Type' },
      { id: 'project', label: 'Project' },
      { id: 'startDate', label: 'Start date' },
      { id: 'endDate', label: 'End date' },
      { id: 'municipality_englishName', label: 'Location' },
    ];

    return (
        <>
          <div className={classes.contentWrapper} style={{width: '95%'}}>
            <TextField
                label="Name, type, location, date, user email"
                id="activity-filter"
                variant="outlined"
                size="small"
                value={searchTerm}
                fullWidth
                onChange={handleSearchTermChanged}
            />
          </div>
          <TableContainer style={{width: '95%'}} component={Paper} className={classes.tableContainer}>
            <Table aria-label="collapsible table">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  {headCells.map((headCell) => (
                      <TableCell key={headCell.id}>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={sortHandler(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                  ))}
                  <TableCell>Has implications for</TableCell>
                  <TableCell>Indicators</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredActivities.map((row) => (
                    <Row key={row.id} keyId={row.id} activity={row} indicators={indicators} fetchData={fetchData} shouldOpen={activities.length === 1} removeHandler={removeActivity} state={state}/>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {state === 'ALL' && authState.role && authState.role !== 'ROLE_READER' &&
            <AddActivityDialog classes={classes}
                             confirmationHandler={addActivityConfirmationHandler}/>
          }
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(ProjectActivities);