import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import StatisticsIcon from '@material-ui/icons/Assessment';
import {UserContext} from "./auth/UserProvider";
import PeopleIcon from '@material-ui/icons/People';
import DescriptionIcon from '@material-ui/icons/Description';

const categories = [
  {
    id: 'General',
    children: [
      { id: 'Dashboard', icon: <StatisticsIcon />,
        href: '/dashboard/0' },
      { id: 'Projects', icon: <StatisticsIcon />,
        href: '/projects' },
    ],
  },
  {
    id: 'Activities',
    children: [
      { id: 'Activity Log', icon: <StatisticsIcon />,
        href: '/activities/0' },
      { id: 'Planned Activities', icon: <StatisticsIcon />,
        href: '/planned-activities/0' },
    ],
  },
  {
    id: 'Project Indicators',
    children: [
      { id: 'Module Goal / Outcome Indicators', icon: <DescriptionIcon />, href: '/module-indicators/0' },
      { id: 'Output', icon: <DescriptionIcon />, href: '/output-indicators/0' },
    ],
  },
  {
    id: 'Common Indicators',
    children: [
      { id: 'Cluster', icon: <DescriptionIcon />,
        href: '/cluster-indicators' },
      { id: 'Risk', icon: <DescriptionIcon />,
        href: '/risk-indicators/0' },
    ],
  },
  {
    id: 'Settings',
    children: [
      { id: 'User Settings', icon: <PeopleIcon />,
        href: '/users' },
    ],
  },
];

const styles = (theme) => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.black,
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: '#000000',
    '&:hover,&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.01)',
    },
  },
  itemCategory: {
    backgroundColor: '#ffffff',
    boxShadow: '0 -1px 0 #C80F0F inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  firebase: {
    fontSize: 12,
    color: theme.palette.common.black,
  },
  itemActiveItem: {
    color: '#C80F0F',
  },
  itemPrimary: {
    fontSize: 'inherit',
  },
  itemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
});

function Navigator(props) {
  const { classes, ...other } = props;

  const authState = useContext(UserContext);

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
          <table>
            <tbody>
              <tr>
                <td>
                  <img src={process.env.PUBLIC_URL + '/giz_logo.png'} alt="logo" width="100px"/>
                </td>
                <td>GIZ Libya Country Program</td>
              </tr>
            </tbody>
          </table>

        </ListItem>

        {authState.user && categories.map(({ id, children }) => (
          <React.Fragment key={id}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {id}
              </ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, active, href }) => (
              <ListItem
                key={childId}
                button
                className={clsx(classes.item, active && classes.itemActiveItem)}
                component="a"
                href={href}
              >
                <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                >
                  {childId}
                </ListItemText>
              </ListItem>
            ))}

            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
}

Navigator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Navigator);
