import React, {useEffect, useRef, useState} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import TextField from "@material-ui/core/TextField";
import {getGoals} from "../../utils/api";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Tooltip
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {Autocomplete} from "@material-ui/lab";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

function AddClusterIndicatorDialog(props) {
  const { classes, confirmationHandler, selectedProject, action = 'create', entity } = props;
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState('');
  const [abbreviation, setAbbreviation] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [goals, setGoals] = React.useState([]);
  const [selectedGoals, setSelectedGoals] = React.useState([]);
  const [disaggregateGender, setDisaggregateGender] = React.useState(false);
  const [disaggregateFunction, setDisaggregateFunction] = React.useState(false);

  const cancelRequest = useRef();

  const fetchGoals =
      async loading => {
        try {
          const result = await getGoals();
          const goalData = result.data;

          if (!cancelRequest.current) {
            if (goalData && Object.keys(goalData).length > 0) {
              setGoals(goalData);
            } else {
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
          }
        }
      };

  useEffect(() => {
    fetchGoals(false);
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
    setName(entity && entity.name ? entity.name : '');
    setAbbreviation(entity && entity.abbreviation ? entity.abbreviation : '');
    setDescription(entity && entity.description ? entity.description : '');
    setSelectedGoals(entity && entity.goals ? entity.goals : []);
    setDisaggregateFunction(entity && entity.disaggregateFunction ? entity.disaggregateFunction : false);
    setDisaggregateGender(entity && entity.disaggregateGender ? entity.disaggregateGender : false);
  };

  const handleClose = () => {
    setOpen(false);
    setName('');
    setAbbreviation('');
    setDescription('');
    setSelectedGoals([]);
    setDisaggregateFunction(false);
    setDisaggregateGender(false);
  };

  const handleConfirm = () => {
    action === 'create' ?
        confirmationHandler(name, abbreviation, description, selectedGoals,
            disaggregateGender, disaggregateFunction) :
        confirmationHandler(
            entity.id, name, abbreviation, description, selectedGoals,
            disaggregateGender, disaggregateFunction);
    handleClose();
  };

  const onNameChange = (e) => {
    setName(e.target.value);
  };

  const onAbbreviationChange = (e) => {
    setAbbreviation(e.target.value);
  };

  const onDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const onDisaggregateGenderChange = (e) => {
    let checked = e.target.checked;
    setDisaggregateGender(checked);
    if (!checked) {
      setDisaggregateFunction(false);
    }
  };

  const onDisaggregateFunctionChange = (e) => {
    setDisaggregateFunction(e.target.checked);
  };

  return (
      <div>
        { action === 'create' &&
          <Fab
              variant="extended"
              size="medium"
              color="primary"
              aria-label="add"
              onClick={handleClickOpen}
              className={classes.fab}
              style={{position: 'fixed'}}
          >
            <AddIcon/>
            Add cluster indicator
          </Fab>
        }
        { action === 'edit' &&
          <Tooltip title="Edit">
            <IconButton aria-label="edit"
                        onClick={handleClickOpen}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        }
        <Dialog fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{ action === 'create' ? 'Add new' : 'Edit'} cluster indicator</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter the necessary information
            </DialogContentText>
            <TextField
                autoFocus
                margin="normal"
                id="name"
                label="Name"
                type="text"
                value={name}
                onChange={onNameChange}
                fullWidth
                variant="outlined"
            />
            <TextField
                margin="normal"
                id="abbreviation"
                label="Short description"
                type="text"
                value={abbreviation}
                onChange={onAbbreviationChange}
                fullWidth
                variant="outlined"
            />
            <TextField
                margin="normal"
                id="description"
                label="Description"
                type="text"
                value={description}
                onChange={onDescriptionChange}
                rows={4}
                multiline
                fullWidth
                variant="outlined"
            />
            <Autocomplete
                multiple
                style={{marginTop: '1rem'}}
                id="goal-multi-select"
                options={goals}
                getOptionLabel={(option) =>  `${option.goalNr}: ${option.name}`}
                value={selectedGoals}
                onChange={(event, value) => setSelectedGoals(value)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label="SDGs"
                    />
                )}
            />
            <FormGroup>
              <FormControlLabel control={<Checkbox checked={disaggregateGender} color="primary" onChange={onDisaggregateGenderChange} />} label="Disaggregate by gender" />
              { disaggregateGender &&
                <FormControlLabel control={<Checkbox checked={disaggregateFunction} color="primary" onChange={onDisaggregateFunctionChange} />} label="Additionally, disaggregate by function" />
              }
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              { action === 'create' ? 'Create' : 'Update'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(AddClusterIndicatorDialog)