import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import {
  addIndicatorValue, createIndicator, deleteIndicator,
  deleteIndicatorValue,
  getIndicators, updateIndicator, updateIndicatorValue
} from "../../utils/api";
import {Chip, CircularProgress, Collapse, TextField} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import ConfirmActionDialog from "../dialogs/ConfirmActionDialog";
import AddRiskIndicatorDialog from "../dialogs/AddRiskIndicatorDialog";
import {formatRisk, formatRiskCategory} from "../../utils/riskTypeHelper";
import AddRiskIndicatorValueDialog
  from "../dialogs/AddRiskIndicatorValueDialog";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Chart from "react-apexcharts";
import {
  Description,
  DescriptionList
} from "../parts/Description.parts";
import GridList from "@material-ui/core/GridList";

const styles = () => ({
  paper: {
    maxWidth: '80%',
    minWidth: '70%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
});

function IndicatorPaper(props) {
  const { classes, indicator, updateHandler, confirmationHandler, removeValueHandler, removeHandler, indicatorType, updateValueHandler } = props;

  const [open, setOpen] = useState(false);
  const [fromDate, setFromDate] = useState(undefined);
  const [toDate, setToDate] = useState(undefined);

  const collapseComponent = (props) => (
      <td colSpan={6}>
        <div className={props.className}>
          {props.children}
        </div>
      </td>
  )

  let chartOptions = {
    chart: {
      id: "basic-line"
    },
    xaxis: {
      type: 'datetime'
    }
  };

  useEffect(() => {
    if (indicator && indicator.indicatorType === 'RISK') {
      let sortedValues =
          indicator.indicatorValues
          .sort(function(a,b){
            return new Date(a.attributionDate) - new Date(b.attributionDate);});

      if (sortedValues.length > 0) {
        setFromDate(sortedValues.length < 4 ? sortedValues[0].attributionDate : sortedValues[sortedValues.length - 4].attributionDate);
        setToDate(sortedValues[sortedValues.length - 1].attributionDate)
      }
    }
  }, [indicator]);

  let chartSeries = [];

  if (indicator.isNumeric && indicator.indicatorValues && indicator.indicatorValues.length > 0) {
    let series = { name: "Value"};

    let data = [];

    let sortedValues =
        indicator.indicatorValues
        .sort(function(a,b){
          return new Date(a.attributionDate) - new Date(b.attributionDate);});

    for (let i = 0; i < sortedValues.length; i++) {
      if (sortedValues[i].attributionDate >= fromDate && sortedValues[i].attributionDate <= toDate)  {
        data.push({ x: sortedValues[i].attributionDate, y: sortedValues[i].riskValue});
      }
    }

    series['data'] = data;

    chartSeries.push(series);
  }

  return <Paper className={classes.paper}>
    <AppBar className={classes.toolbar}
            position="static"
            color="default"
            elevation={0}>
      <Toolbar>
        <Typography variant="h6" gutterBottom component="div" style={{ padding: '0.2rem' }}>
          {indicator.name}
        </Typography>
        <div style={{position: 'absolute', right: '1rem', top: '0.2rem', display: 'flex', flexDirection: 'row'}}>
          {indicator.riskCategory && indicator.riskCategory !== '' &&
            <Chip label={formatRiskCategory(indicator.riskCategory)} style={{ marginRight: '1rem', marginTop: '0.3rem' }}/>
          }
          <AddRiskIndicatorDialog indicatorType={indicatorType} action={'edit'} entity={indicator} confirmationHandler={updateHandler} />
          { indicator.indicatorValues && indicator.indicatorValues.length === 0 &&
            <ConfirmActionDialog confirmationHandler={() => removeHandler(indicator.id)}/>
          }
        </div>
      </Toolbar>
    </AppBar>
    <div className={classes.contentWrapper}>
      <Typography variant="subtitle1">
        Risk: <strong>{formatRisk(indicator.risk)}</strong>
      </Typography>
      <Typography variant="subtitle1">
        Responsibility: <strong>{indicator.responsible}</strong>
      </Typography>
      <Typography variant="subtitle1">
        Current value: <strong>{indicator.indicatorValues && indicator.indicatorValues.length > 0 && indicator.indicatorValues.sort(function(a,b){return new Date(b.attributionDate) - new Date(a.attributionDate);})[0].riskValue}</strong>
      </Typography>
      <Typography style={{ marginTop: '1rem' }} color="textSecondary">
        {indicator.description}
      </Typography>
      {indicator.isNumeric &&
        <>
          <div>
            <Chart
                options={chartOptions}
                series={chartSeries}
                type="line"
                width="500"
            />
          </div>
          <GridList cellHeight={'auto'} className={classes.gridList} cols={3}>
            <DescriptionList>
              <Description>
                <TextField
                    margin="normal"
                    id="fromDate"
                    label="From"
                    type="date"
                    value={fromDate}
                    variant={'outlined'}
                    onChange={(e) => setFromDate(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                />
              </Description>
            </DescriptionList>
            <DescriptionList>
              <Description>
                <TextField
                    margin="normal"
                    id="toDate"
                    label="To"
                    type="date"
                    value={toDate}
                    variant={'outlined'}
                    onChange={(e) => setToDate(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                />
              </Description>
            </DescriptionList>
          </GridList>
        </>
      }
    </div>
    <TableContainer className={classes.tableContainer}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell width={'25%'}>Description</TableCell>
            <TableCell width={'10%'}>Date</TableCell>
            <TableCell width={'10%'}>Added by</TableCell>
            <TableCell width={'5%'}>Value</TableCell>
            <TableCell width={'10%'}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Collapse style={{marginBottom: '1rem'}} in={open} timeout="auto" component={collapseComponent} unmountOnExit>
            {indicator.indicatorValues.sort(function(a,b){return new Date(b.attributionDate) - new Date(a.attributionDate);}).map((indicatorValue) => (
                <TableRow key={indicatorValue.id}>
                  <TableCell/>
                  <TableCell width={'30%'}
                             style={{
                               whiteSpace: "normal",
                               wordBreak: "break-word"
                             }}>{indicatorValue.description}</TableCell>
                  <TableCell width={'10%'}>{`${(new Date(indicatorValue.attributionDate).getMonth() + 1) / 3}/${new Date(indicatorValue.attributionDate).getFullYear()}`}</TableCell>
                  <TableCell width={'10%'}>{indicatorValue.createdBy}</TableCell>
                  <TableCell width={'5%'}><strong>{indicatorValue.riskValue}</strong></TableCell>
                  <TableCell width={'10%'}>
                    <div style={{display: 'flex'}} >
                      <AddRiskIndicatorValueDialog action={'edit'} indicator={indicator} entity={indicatorValue} confirmationHandler={updateValueHandler} />
                      <ConfirmActionDialog confirmationHandler={() => removeValueHandler(indicator.id, indicatorValue.id)}/>
                    </div>
                  </TableCell>
                </TableRow>
            ))}
          </Collapse>
        </TableBody>
      </Table>
    </TableContainer>
    <div className={classes.contentWrapper}>
      <AddRiskIndicatorValueDialog indicator={indicator} confirmationHandler={confirmationHandler}/>
    </div>
  </Paper>
}

function IndicatorsRisk(props) {
  const { classes, indicatorType, riskCategory } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [indicators, setIndicators] = useState('');

  const cancelRequest = useRef();

  const removeIndicator = async (indicatorId) => {
    await deleteIndicator(indicatorId);
    await fetchData();
  };

  const removeIndicatorValue = async (indicatorId, indicatorValueId) => {
    await deleteIndicatorValue(indicatorId, indicatorValueId);
    await fetchData();
  };

  const indicatorConfirmationHandler =
      async (name, abbreviation, description, riskCategory, risk, responsible, isNumeric) => {
        let body = {
          'indicatorType': 'RISK',
          'name': name,
          'abbreviation': abbreviation,
          'description': description,
          'risk': risk,
          'riskCategory': riskCategory,
          'responsible': responsible,
          'isNumeric': isNumeric
        }

        await createIndicator(body);
        await fetchData();
      };

  const indicatorValueConfirmationHandler =
      async (indicatorId, description, attributionDate, riskValue) => {
        await addIndicatorValue(indicatorId, {
          'description': description,
          'attributionDate': attributionDate,
          'riskValue': riskValue
        });
        await fetchData()
      };

  const updateConfirmationHandler =
      async (entityId, name, abbreviation, description, riskCategory, risk, responsible, isNumeric) => {
        let body = {
          'indicatorType': indicatorType,
          'name': name,
          'abbreviation': abbreviation,
          'description': description,
          'risk': risk,
          'riskCategory': riskCategory,
          'responsible': responsible,
          'isNumeric': isNumeric
        }

        await updateIndicatorDataAndDispatch(entityId, body);
        await fetchData();
      };

  const confirmIndicatorValueUpdate =
      async (indicatorValueId, indicatorId, description, attributionDate, riskValue) => {
        await updateIndicatorValueDataAndDispatch(indicatorId, indicatorValueId, {
          'description': description,
          'attributionDate': attributionDate,
          'riskValue': riskValue
        });
        await fetchData();
      };

  const updateIndicatorDataAndDispatch = async (indicatorId, fields) => {
    try {
      await updateIndicator(
          indicatorId,
          fields
      );
    } catch (error) {
    }
  };


  const updateIndicatorValueDataAndDispatch = async (indicatorId, indicatorValueId, fields) => {
    try {
      await updateIndicatorValue(
          indicatorId,
          indicatorValueId,
          fields
      );
    } catch (error) {
    }
  };

  const fetchData =
      async loading => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getIndicators();
          const indicatorData = result.data;

          if (!cancelRequest.current) {
            if (indicatorData && Object.keys(indicatorData).length > 0) {
              setIndicators(indicatorData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false);
  }, []);

  const renderData = () => {
    if (isError) {
      return (
          <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
            <Typography variant="h6" color="primary" style={{marginTop: '10%'}}>
              Unable to fetch indicators or no indicators were added in this category!
            </Typography>
          </div>
      );
    }

    if (isLoading || !indicators || !riskCategory) {
      return (
          <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
            <CircularProgress style={{marginTop: '10%'}} color="primary" />
          </div>
      );
    }

    return (
        <>
          { indicators.filter(i => i.indicatorType === indicatorType && i.riskCategory === riskCategory).sort(function(a, b) {return a.id - b.id;}).map((indicator) => {
              return <IndicatorPaper key={indicator.id} classes={classes} indicator={indicator} updateHandler={updateConfirmationHandler} confirmationHandler={indicatorValueConfirmationHandler} removeHandler={removeIndicator} removeValueHandler={removeIndicatorValue} updateValueHandler={confirmIndicatorValueUpdate} />
          })}
          <AddRiskIndicatorDialog confirmationHandler={indicatorConfirmationHandler} />
        </>
    );
  };

  return renderData();
}

IndicatorsRisk.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(IndicatorsRisk);
