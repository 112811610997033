import React, {useEffect, useRef, useState} from 'react';
import {CircularProgress,} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import {
  getProjects,
  getProjectStatistics,
} from "../../utils/api";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import {formatActivityType} from "../../utils/activityTypeHelper";
import {formatTime} from "../../utils/timeHelper";
import {truncate} from "../../utils/stringHelper";

const styles = () => ({
  paper: {
    padding: 15,
    marginTop: 10
  },
});

const StyledLinearProgress = withStyles((theme) => ({
  root: {
    height: 24,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
  },
  main: {
    flex: 1,
    height: '100%',
    padding: theme.spacing(6, 4),
    background: '#eaeff1',
  },
}))(LinearProgressWithLabel);

function LinearProgressWithLabel(props) {
  const { value, current, target, classes } = props;

  return (
      <Box style={{ marginTop: '0.3rem', marginBottom: '0.3rem' }} display="flex" alignItems="center">
        <Box width="90%" mr={1}>
          <LinearProgress variant="determinate" value={value > 100 ? 100 : value} classes={classes} />
        </Box>
        <Box minWidth={100}>
          <Typography variant="body2" color="textSecondary">
            {current} / {target}
          </Typography>
        </Box>
      </Box>
  );
}

function ProjectDashboard(props) {
  const { classes, prjct } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isForbidden, setIsForbidden] = useState(false);
  const [statistics, setStatistics] = useState('');

  const cancelRequest = useRef();

  const onProjectChange = (prjct) => {
    setStatistics(null);
    fetchProjectData(false, prjct.id);
  };

  const fetchProjectData = async (loading, projectId) => {
    setIsError(false);
    setIsLoading(loading);
    try {
      const result = await getProjectStatistics(projectId);
      const statisticsData = result.data;

      if (!cancelRequest.current) {
        if (statisticsData && Object.keys(statisticsData).length > 0) {
          setStatistics(statisticsData);
        } else {
          setIsError(true);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setIsForbidden(true);
      } else if (!cancelRequest.current) {
        setIsError(true);
      }
    }
  };

  const fetchProjects = async (loading) => {
    setIsError(false);
    setIsLoading(loading);
    try {
      const result = await getProjects();
      const pojectsData = result.data;

      if (!cancelRequest.current) {
        if (pojectsData && Object.keys(pojectsData).length > 0) {
          setIsLoading(false);
        } else {
          setIsError(true);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setIsForbidden(true);
      } else if (!cancelRequest.current) {
        setIsError(true);
      }
    }
  };

  useEffect(() => {
    fetchProjects(true);
    onProjectChange(prjct);
  }, []);

  const position = [26.3347113, 17.2692101];

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isForbidden) {
      return (
          <>
            Access denied!
          </>
      );
    }

    if (isLoading || !statistics) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    return (
      <div style={{margin: 10,}}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper style={{ height: '100%' }} variant="outlined" className={classes.paper}>
              <Typography variant="h5" color="textPrimary" style={{paddingBottom: '1rem'}}>
                Map of ongoing/upcoming activities
              </Typography>
              <MapContainer center={position} zoom={5} scrollWheelZoom={false} style={{height: 450, paddingTop: '2rem'}}>
                <TileLayer
                    attribution='&copy; <a href="https://carto.com/">carto.com</a> contributors'
                    url='https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png'
                />
                { statistics.municipalityStatistics && statistics.municipalityStatistics.map((municipality) => (
                    <>
                      { municipality.lat && municipality.lng &&
                      <Marker
                          position={[municipality.lat, municipality.lng]} key={municipality.id}>
                        <Popup>
                          <div style={{fontSize: 14, fontWeight: 'bold'}}>{municipality.englishName}</div>
                          {municipality.ongoingActivities && municipality.ongoingActivities.length > 0 &&
                          <strong>Ongoing:</strong>
                          }
                          <ul>
                            {municipality.ongoingActivities && municipality.ongoingActivities.map((a) => (
                                <li><a href={'/activity/' + a.id}>{`${a.name} (${formatTime(a.startDate)}) ${a.project ? '- ' + a.project.titleShort : ''}`}</a></li>
                            ))}
                          </ul>
                          {municipality.activitiesPlanned && municipality.activitiesPlanned.length > 0 &&
                          <strong>Planned:</strong>
                          }
                          <ul>
                            {municipality.activitiesPlanned && municipality.activitiesPlanned.map((a) => (
                                <li><a href={'/activity/' + a.id}>{`${a.name} (${formatTime(a.startDate)}) ${a.project ? '- ' + a.project.titleShort : ''}`}</a></li>
                            ))}
                          </ul>
                        </Popup>
                      </Marker>
                      }
                    </>
                ))}
              </MapContainer>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
             <Paper style={{ height: '100%' }} variant="outlined" className={classes.paper}>
              <Typography variant="h5" color="textPrimary" style={{paddingBottom: '1rem'}}>
                Ongoing / Upcoming activities
              </Typography>
              {statistics.upcomingActivities.length > 0 &&
              <TableContainer>
                <Table aria-label="collapsible table">
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <TableCell width={'40%'}>Name</TableCell>
                      <TableCell width={'30%'}>Type</TableCell>
                      <TableCell width={'30%'}>Start date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {statistics.upcomingActivities
                    && statistics.upcomingActivities.slice(0, 6).map(
                        (activity) => (
                            <TableRow key={activity.id}>
                              <TableCell component="th"
                                         scope="row"><a href={'/activity/' + activity.id}>{activity.name}</a></TableCell>
                              <TableCell>{formatActivityType(
                                  activity.activityType)}</TableCell>
                              <TableCell>{formatTime(
                                  activity.startDate)}</TableCell>
                            </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </TableContainer>
              }
              {statistics.upcomingActivities.length === 0 &&
              <Typography variant="subtitle1" color="textPrimary" style={{paddingBottom: '1rem'}}>
                There are no upcoming activities yet!
              </Typography>
              }
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
             <Paper style={{ height: '100%' }} variant="outlined" className={classes.paper}>
              <Typography variant="h5" color="textPrimary" style={{paddingBottom: '1rem'}}>
                Module Goal / Outcome Indicators
              </Typography>
              { statistics.moduleProgressDetails && statistics.moduleProgressDetails.map((module) => (
                  <div className={classes.contentWrapper} key={module.indicatorShortName}>
                    <Typography variant="subtitle1" color="textPrimary">{module.indicatorName}</Typography>
                    <Typography variant="caption">{truncate(module.description, 140)}</Typography>
                    <StyledLinearProgress
                        value={module.progress * 100}
                        current={module.currentValue}
                        target={module.targetValue}/>
                  </div>
              ))}
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper style={{ height: '100%' }} variant="outlined" className={classes.paper}>
              <Typography variant="h5" color="textPrimary" style={{paddingBottom: '1rem'}}>
                Recently finalized activities
              </Typography>
              {statistics.pastActivities.length > 0 &&
              <TableContainer>
                <Table aria-label="collapsible table">
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <TableCell width={'40%'}>Name</TableCell>
                      <TableCell width={'30%'}>Type</TableCell>
                      <TableCell width={'30%'}>Start date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {statistics.pastActivities
                    && statistics.pastActivities.slice(0, 7).map(
                        (activity) => (
                            <TableRow key={activity.id}>
                              <TableCell component="th"
                                         scope="row"><a href={'/activity/' + activity.id}>{activity.name}</a></TableCell>
                              <TableCell>{formatActivityType(
                                  activity.activityType)}</TableCell>
                              <TableCell>{formatTime(
                                  activity.startDate)}</TableCell>
                            </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </TableContainer>
              }
              {statistics.pastActivities.length === 0 &&
              <Typography variant="subtitle1" color="textPrimary" style={{paddingBottom: '1rem'}}>
                There are no past activities yet!
              </Typography>
              }
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
             <Paper style={{ height: '100%' }} variant="outlined" className={classes.paper}>
              <Typography variant="h5" color="textPrimary" style={{paddingBottom: '1rem'}}>
                Output Indicators
              </Typography>
              { statistics.outputProgressDetails && statistics.outputProgressDetails.map((output) => (
                  <div className={classes.contentWrapper} key={output.indicatorShortName}>
                    <Typography variant="subtitle1" color="textPrimary">{output.indicatorName}</Typography>
                    <Typography variant="caption">{truncate(output.description, 140)}</Typography>
                    <StyledLinearProgress
                        value={output.progress * 100}
                        current={output.currentValue}
                        target={output.targetValue}/>
                  </div>
              ))}
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  };

  return renderData();
}

export default withStyles(styles)(ProjectDashboard);