import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

var firebaseConfig = {
  apiKey: "AIzaSyCKtuEuut_Y5BR6p1qa-PBmi6guApvXQO0",
  authDomain: "gizlibya-543d7.firebaseapp.com",
  projectId: "gizlibya-543d7",
  storageBucket: "gizlibya-543d7.appspot.com",
  messagingSenderId: "110703247339",
  appId: "1:110703247339:web:5547068731239c9d1623ae"
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();