import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import {
  addIndicatorValue, createIndicator, deleteIndicator,
  deleteIndicatorValue,
  getIndicators, updateIndicator, updateIndicatorValue
} from "../../utils/api";
import {CircularProgress, Collapse} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {formatActivityType} from "../../utils/activityTypeHelper";
import {formatTime} from "../../utils/timeHelper";
import Tooltip from "@material-ui/core/Tooltip";
import ConfirmActionDialog from "../dialogs/ConfirmActionDialog";
import AddClusterIndicatorDialog from "../dialogs/AddClusterIndicatorDialog";
import AddClusterIndicatorValueDialog
  from "../dialogs/AddClusterIndicatorValueDialog";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const styles = () => ({
  paper: {
    maxWidth: '80%',
    minWidth: '70%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
});

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    border: '1px solid #dadde9',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 12,
  },
}))(Tooltip);

function IndicatorPaper(props) {
  const { classes, indicator, updateHandler, confirmationHandler, removeValueHandler, removeHandler, indicatorType, updateValueHandler } = props;

  const [open, setOpen] = useState(false);

  const arrSumFemales = arr => arr.filter(v => v.nrFemales !== undefined).reduce((a, b) => a + b.nrFemales, 0);
  const arrSumMales = arr => arr.filter(v => v.nrMales !== undefined).reduce((a, b) => a + b.nrMales, 0);
  const arrSum = arr => arr.filter(v => v.numericValue !== undefined).reduce((a, b) => a + b.numericValue, 0);

  const collapseComponent = (props) => (
      <td colSpan={9}>
        <div className={props.className}>
          {props.children}
        </div>
      </td>
  )

  const year = new Date().getFullYear();

  let currentYearValues = [];
  let lastYearValues = [];
  let yearBeforeLastValues = [];

  if (indicator.indicatorValues) {
    currentYearValues =
        indicator.indicatorValues
          .filter(v => new Date(v.attributionDate).getFullYear() === year);
    lastYearValues =
        indicator.indicatorValues
        .filter(v => new Date(v.attributionDate).getFullYear() === year - 1);
    yearBeforeLastValues =
        indicator.indicatorValues
        .filter(v => new Date(v.attributionDate).getFullYear() === year - 2);
  }

  return <Paper className={classes.paper}>
    <AppBar className={classes.toolbar}
            position="static"
            color="default"
            elevation={0}>
      <Toolbar>
        <Typography variant="h6" gutterBottom component="div">
          {indicator.name}
        </Typography>
        <div style={{position: 'absolute', right: '1rem', top: '0.2rem', display: 'flex', flexDirection: 'row'}}>
          <AddClusterIndicatorDialog indicatorType={indicatorType} action={'edit'} entity={indicator} confirmationHandler={updateHandler} />
          { indicator.indicatorValues && indicator.indicatorValues.length === 0 &&
            <ConfirmActionDialog confirmationHandler={() => removeHandler(indicator.id)}/>
          }
        </div>
      </Toolbar>
    </AppBar>
    <div className={classes.contentWrapper}>
      <Typography color="textSecondary">
        {indicator.description}
      </Typography>
    </div>
    <div className={classes.contentWrapper}>
      <Typography variant="subtitle1">
        Sustainable development goals
      </Typography>
      <Typography color="textSecondary">
        <ul>
          {indicator.goals.map((goal) => (
              <li>{`${goal.goalNr}: ${goal.name}`}</li>
          ))}
        </ul>
      </Typography>
    </div>
    <div className={classes.contentWrapper}>
      <Typography variant="subtitle1">
        Summary for {year}
      </Typography>
      <Typography color="textSecondary" style={{ marginTop: '1rem' }}>
        { indicator.disaggregateGender && currentYearValues !== undefined &&
        <>{`Female: ${arrSumFemales(currentYearValues)} Male: ${arrSumMales(currentYearValues)}`}</>
        }
        { !indicator.disaggregateGender && currentYearValues !== undefined &&
        <>{`Total: ${arrSum(currentYearValues)}`}</>
        }
      </Typography>
    </div>
    {lastYearValues.length > 0 &&
      <div className={classes.contentWrapper}>
        <Typography variant="subtitle1">
          Summary for {year - 1 }
        </Typography>
        <Typography color="textSecondary" style={{marginTop: '1rem'}}>
          {indicator.disaggregateGender &&
          <>{`Female: ${arrSumFemales(lastYearValues)} Male: ${arrSumMales(
              lastYearValues)}`}</>
          }
          {!indicator.disaggregateGender &&
          <>{`Total: ${arrSum(lastYearValues)}`}</>
          }
        </Typography>
      </div>
    }
    {yearBeforeLastValues.length > 0 &&
      <div className={classes.contentWrapper}>
        <Typography variant="subtitle1">
          Summary for {year - 2}
        </Typography>
        <Typography color="textSecondary" style={{marginTop: '1rem'}}>
          {indicator.disaggregateGender &&
          <>{`Female: ${arrSumFemales(yearBeforeLastValues)} Male: ${arrSumMales(
              yearBeforeLastValues)}`}</>
          }
          {!indicator.disaggregateGender &&
          <>{`Total: ${arrSum(yearBeforeLastValues)}`}</>
          }
        </Typography>
      </div>
    }
    <TableContainer className={classes.tableContainer}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell width={'25%'}>Description</TableCell>
            <TableCell width={'10%'}>Activity</TableCell>
            <TableCell width={'10%'}>Date</TableCell>
            <TableCell width={'10%'}>Added by</TableCell>
            { indicator.disaggregateFunction &&
              <TableCell width={'10%'}>Function</TableCell>
            }
            { indicator.disaggregateGender &&
              <>
                <TableCell width={'10%'}>Female</TableCell>
                <TableCell width={'10%'}>Male</TableCell>
              </>
            }
            { !indicator.disaggregateGender &&
              <TableCell width={'10%'}>Value</TableCell>
            }
            <TableCell width={'10%'}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Collapse style={{marginBottom: '1rem'}} in={open} timeout="auto" component={collapseComponent} unmountOnExit>
            {indicator.indicatorValues.sort(function(a,b){return new Date(b.attributionDate) - new Date(a.attributionDate);}).map((indicatorValue) => (
                <TableRow key={indicatorValue.id}>
                  <TableCell/>
                  <TableCell width={'30%'}
                             style={{
                               whiteSpace: "normal",
                               wordBreak: "break-word"
                             }}>{indicatorValue.description}</TableCell>
                  <TableCell width={'10%'}>
                    {indicatorValue.activity &&
                    <HtmlTooltip
                        title={
                          <>
                            <Typography color="inherit">{indicatorValue.activity.name}</Typography>
                            Type: {formatActivityType(indicatorValue.activity.activityType)} <br/>
                            Name: {indicatorValue.activity.name} <br/>
                            Location: {indicatorValue.activity.location} <br/>
                            Date: {formatTime(indicatorValue.activity.date, 'dd.LL.yyyy')} <br/>
                          </>
                        }
                    >
                      <span><a href={'/activity/' + indicatorValue.activity.id}>{indicatorValue.activity.name}</a></span>
                    </HtmlTooltip>
                    }
                  </TableCell>
                  <TableCell width={'10%'}>{formatTime(indicatorValue.attributionDate, 'dd.LL.yyyy')}</TableCell>
                  <TableCell width={'10%'}>{indicatorValue.createdBy}</TableCell>
                  { indicator.disaggregateFunction &&
                    <TableCell width={'10%'}><strong>{indicatorValue.fct}</strong></TableCell>
                  }
                  { indicator.disaggregateGender &&
                  <>
                    <TableCell width={'10%'}><strong>{indicatorValue.nrFemales}</strong></TableCell>
                    <TableCell width={'10%'}><strong>{indicatorValue.nrMales}</strong></TableCell>
                  </>
                  }
                  { !indicator.disaggregateGender &&
                    <TableCell width={'10%'}><strong>{indicatorValue.numericValue}</strong></TableCell>
                  }
                  <TableCell width={'10%'}>
                    <div style={{display: 'flex'}} >
                      <AddClusterIndicatorValueDialog action={'edit'} indicator={indicator} entity={indicatorValue} confirmationHandler={updateValueHandler} />
                      <ConfirmActionDialog confirmationHandler={() => removeValueHandler(indicator.id, indicatorValue.id)}/>
                    </div>
                  </TableCell>
                </TableRow>
            ))}
          </Collapse>
        </TableBody>
      </Table>
    </TableContainer>
    <div className={classes.contentWrapper}>
      <AddClusterIndicatorValueDialog indicator={indicator} confirmationHandler={confirmationHandler}/>
    </div>
    { indicator.activities && indicator.activities.length > 0 &&
      <>
        <div className={classes.contentWrapper}>
          <Typography  variant="h6" color="textSecondary">
            Additional activities contributing to this indicator
          </Typography>
        </div>
        <TableContainer className={classes.tableContainer}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell width={'25%'}>Name</TableCell>
                <TableCell width={'10%'}>Start date</TableCell>
                <TableCell width={'10%'}>End date</TableCell>
                <TableCell width={'10%'}>Municipality</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {indicator.activities.map((a) => (
                  <TableRow key={a.id}>
                    <TableCell width={'30%'}>{a.name}</TableCell>
                    <TableCell width={'10%'}>{formatTime(a.startDate)}</TableCell>
                    <TableCell width={'10%'}>{formatTime(a.endDate)}</TableCell>
                    <TableCell width={'10%'}>{a.municipality ? a.municipality.englishName + ' (' + a.municipality.arabicName + ')' : ''}</TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    }
  </Paper>
}

function IndicatorsCluster(props) {
  const { classes, indicatorType } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [indicators, setIndicators] = useState('');

  const cancelRequest = useRef();

  const removeIndicator = async (indicatorId) => {
    await deleteIndicator(indicatorId);
    await fetchData();
  };

  const removeIndicatorValue = async (indicatorId, indicatorValueId) => {
    await deleteIndicatorValue(indicatorId, indicatorValueId);
    await fetchData();
  };

  const indicatorValueConfirmationHandler =
      async (indicatorId, description, attributionDate, activity, fct, females, males, value) => {
        let body = {
          'description': description,
          'activity': activity,
          'attributionDate': attributionDate,
        };

        if (fct && fct !== '') {
          body.fct = fct;
        }

        if (females >= 0) {
          body.nrFemales = +females;
          body.nrMales = 0;
        }

        if (males >= 0) {
          body.nrMales = +males;
          if (females === undefined) {
            body.nrFemales = 0;
          }
        }

        if (+value > 0) {
          body.numericValue = +value;
        } else {
          body.numericValue = 0;
        }

        await addIndicatorValue(indicatorId, body);
        await fetchData()
      };

  const indicatorConfirmationHandler =
      async (name, abbreviation, description, selectedGoals,
          disaggregateGender, disaggregateFunction) => {
        let body = {
          'indicatorType': 'CLUSTER',
          'name': name,
          'abbreviation': abbreviation,
          'description': description,
          'goals': selectedGoals,
          'disaggregateGender': disaggregateGender,
          'disaggregateFunction': disaggregateFunction
        }

        await createIndicator(body);
        await fetchData();
      };

  const updateConfirmationHandler =
      async (entityId, name, abbreviation, description, selectedGoals,
          disaggregateGender, disaggregateFunction) => {
        let body = {
          'indicatorType': indicatorType,
          'name': name,
          'abbreviation': abbreviation,
          'description': description,
          'goals': selectedGoals,
          'disaggregateGender': disaggregateGender,
          'disaggregateFunction': disaggregateFunction
        }

        await updateIndicatorDataAndDispatch(entityId, body);
        await fetchData();
      };

  const confirmIndicatorValueUpdate =
      async (indicatorValueId, indicatorId, description, attributionDate, activity, fct, females, males, value) => {
        let body = {
          'description': description,
          'activity': activity,
          'attributionDate': attributionDate,
        };

        if (fct && fct !== '') {
          body.fct = fct;
        }

        if (females >= 0) {
          body.nrFemales = +females;
          body.nrMales = 0;
        }

        if (males >= 0) {
          body.nrMales = +males;
          if (females === undefined) {
            body.nrFemales = 0;
          }
        }

        if (value > 0) {
          body.numericValue = +value;
        } else {
          body.numericValue = 0;
        }

        await updateIndicatorValueDataAndDispatch(indicatorId, indicatorValueId, body);
        await fetchData();
      };

  const updateIndicatorDataAndDispatch = async (indicatorId, fields) => {
    try {
      await updateIndicator(
          indicatorId,
          fields
      );
    } catch (error) {
    }
  };


  const updateIndicatorValueDataAndDispatch = async (indicatorId, indicatorValueId, fields) => {
    try {
      await updateIndicatorValue(
          indicatorId,
          indicatorValueId,
          fields
      );
    } catch (error) {
    }
  };

  const fetchData =
      async loading => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getIndicators();
          const indicatorData = result.data;

          if (!cancelRequest.current) {
            if (indicatorData && Object.keys(indicatorData).length > 0) {
              setIndicators(indicatorData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false);
  }, []);

  const renderData = () => {
    if (isError) {
      return (
          <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
            <Typography variant="h6" color="primary" style={{marginTop: '10%'}}>
              Unable to fetch indicators or no indicators were added in this category!
            </Typography>
          </div>
      );
    }

    if (isLoading || !indicators) {
      return (
          <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
            <CircularProgress style={{marginTop: '10%'}} color="primary" />
          </div>
      );
    }

    return (
        <>
          { indicators.filter(i => i.indicatorType === indicatorType).sort(function(a, b) {return a.id - b.id;}).map((indicator) => {
              return <IndicatorPaper key={indicator.id} classes={classes} indicator={indicator} updateHandler={updateConfirmationHandler} confirmationHandler={indicatorValueConfirmationHandler} removeHandler={removeIndicator} removeValueHandler={removeIndicatorValue} updateValueHandler={confirmIndicatorValueUpdate} />
          })}
          <AddClusterIndicatorDialog confirmationHandler={indicatorConfirmationHandler} />
        </>
    );
  };

  return renderData();
}

IndicatorsCluster.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(IndicatorsCluster);
