import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import BasicAutocomplete from "../autocompletes/BasicAutocomplete";
import {searchActivities} from "../../utils/api";
import {Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

export default function AddIndicatorValueDialog(props) {
  const { indicator, entity, confirmationHandler, action = 'create', disableActivityUpdate = false } = props;
  const [open, setOpen] = useState(false);
  const [indicatorValueDescription, setIndicatorValueDescription] = useState('');
  const [indicatorValueActivity, setIndicatorValueActivity] = useState(undefined);
  const [indicatorValueComment, setIndicatorValueComment] = useState('');
  const [numericValue, setNumericValue] = useState(0);
  const [secondaryNumericValue, setSecondaryNumericValue] = useState(0);
  const [tertiaryNumericValue, setTertiaryNumericValue] = useState(0);

  const handleClickOpen = () => {
    setOpen(true);
    setIndicatorValueDescription(entity && entity.description ? entity.description : '');
    setIndicatorValueActivity(entity && entity.activity ? entity.activity : undefined);
    setIndicatorValueComment(entity && entity.comment ? entity.comment : '');
    setNumericValue(entity && entity.numericValue ? entity.numericValue : 0);
    setSecondaryNumericValue(entity && entity.secondaryNumericValue ? entity.secondaryNumericValue : 0);
    setTertiaryNumericValue(entity && entity.tertiaryNumericValue ? entity.tertiaryNumericValue : 0);
  };

  const handleClose = () => {
    setOpen(false);
    clearFormData();
  };

  const handleConfirm = () => {
    action === 'create' ?
      confirmationHandler(
          indicator.id,
          indicatorValueDescription,
          indicatorValueActivity,
          indicatorValueComment,
          numericValue,
          indicator && indicator.secondaryTargetValueNumeric ? secondaryNumericValue : null,
          indicator && indicator.tertiaryTargetValueNumeric ? tertiaryNumericValue : null) :
        confirmationHandler(
            entity.id,
            indicator.id,
            indicatorValueDescription,
            indicatorValueActivity,
            indicatorValueComment,
            numericValue,
            indicator && indicator.secondaryTargetValueNumeric ? secondaryNumericValue : null,
            indicator && indicator.tertiaryTargetValueNumeric ? tertiaryNumericValue : null);
    handleClose();
  };

  const clearFormData = () => {
    setIndicatorValueDescription('');
    setIndicatorValueActivity(undefined);
    setIndicatorValueComment('');
    setNumericValue(0);
    setSecondaryNumericValue(0);
    setTertiaryNumericValue(0);
  };

  const onIndicatorValueDescriptionChange = (e) => {
    setIndicatorValueDescription(e.target.value);
  };

  const onIndicatorValueCommentChange = (e) => {
    setIndicatorValueComment(e.target.value);
  };

  const onNumericValueChange = (e) => {
    setNumericValue(e.target.value);
  };

  const onSecondaryNumericValueChange = (e) => {
    setSecondaryNumericValue(e.target.value);
  };

  const onTertiaryNumericValueChange = (e) => {
    setTertiaryNumericValue(e.target.value);
  };

  const changeInput = value => {
    setIndicatorValueActivity(value);
  };

  return (
      <div>
        {action === 'create' &&
          <Button variant="outlined" color="primary" onClick={handleClickOpen}>
            Add indicator value
          </Button>
        }
        { action === 'edit' &&
        <Tooltip title="Edit">
          <IconButton aria-label="edit"
                      onClick={handleClickOpen}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        }
        <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{ action === 'create' ? 'Add new' : 'Edit'} indicator value</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please fill in the necessary information
            </DialogContentText>
            <TextField
                autoFocus
                margin="normal"
                id="name"
                label="Description"
                type="text"
                value={indicatorValueDescription}
                onChange={onIndicatorValueDescriptionChange}
                fullWidth
                variant="outlined"
            />
            { !disableActivityUpdate &&
              <BasicAutocomplete handleChange={changeInput} searchFct={searchActivities} currentValue={indicatorValueActivity} label={'Activity'} property={'name'}/>
            }
            <TextField
                autoFocus
                margin="normal"
                id="name"
                label="Comment"
                type="text"
                value={indicatorValueComment}
                onChange={onIndicatorValueCommentChange}
                fullWidth
                variant="outlined"
            />
            <TextField
                autoFocus
                margin="normal"
                id="name"
                label="Value"
                value={numericValue}
                onChange={onNumericValueChange}
                type="number"
                variant="outlined"
            />
            { indicator && indicator.secondaryTargetValueNumeric &&
                <>
                  <br/>
                  <TextField
                      autoFocus
                      margin="normal"
                      id="name"
                      label="Secondary value"
                      value={secondaryNumericValue}
                      onChange={onSecondaryNumericValueChange}
                      type="number"
                      variant="outlined"
                  />
                </>
            }
            { indicator && indicator.tertiaryTargetValueNumeric &&
              <>
                <br/>
                <TextField
                    autoFocus
                    margin="normal"
                    id="name"
                    label="Tertiary value"
                    value={tertiaryNumericValue}
                    onChange={onTertiaryNumericValueChange}
                    type="number"
                    variant="outlined"
                />
              </>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              { action === 'create' ? 'Add' : 'Update'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}