import React, {useContext} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import PropTypes from 'prop-types';
import { createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Navigator from './Navigator';
import ProtectedRoute from "./auth/ProtectedRoute";
import Dashboard from "./dashboard/Dashboard";
import Header from "./Header";
import UserSettings from "./settings/UserSettings";
import Activities from "./activities/Activities";
import Projects from "./projects/Projects";
import OutputIndicators from "./indicators/OutputIndicators";
import RiskIndicators from "./indicators/RiskIndicators";
import ClusterIndicators from "./indicators/ClusterIndicators";
import {UserContext} from "./auth/UserProvider";
import ModuleIndicators from "./indicators/ModuleIndicators";
import TabbedActivities from "./activities/TabbedActivities";
import Imprint from "./Imprint";
import DataProtection from "./DataProtection";
import Activity from "./activities/Activity";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <a href={'/imprint'}>Imprint</a> | <a href={'/data-protection'}>Data Protection Policy</a>
    </Typography>
  );
}

let theme = createMuiTheme({
  palette: {
    primary: {
      light: '#ffffff',
      main: '#C80F0F',
      dark: '#000000',
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#ffffff',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        margin: '0 16px',
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#C80F0F',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
};

const drawerWidth = 256;

const styles = {
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    padding: theme.spacing(3, 3),
    background: '#eaeff1',
  },
  mainTabbed: {
    flex: 1,
    background: '#eaeff1',
    padding: 0,
  },
  footer: {
    padding: theme.spacing(2),
    background: '#eaeff1',
  },
};

function Paperbase(props) {
  const { classes } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const authState = useContext(UserContext);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const query = new URLSearchParams(document.location.search);

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <nav className={classes.drawer}>
          <Hidden smUp implementation="js">
            <Navigator
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
            />
          </Hidden>
          <Hidden xsDown implementation="css">
            <Navigator PaperProps={{ elevation: 6, style: { width: drawerWidth } }} />
          </Hidden>
        </nav>
        <div className={classes.app}>
          <BrowserRouter>
            <Switch>
              <ProtectedRoute exact path={["/dashboard/:tab", "/"]}>
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Dashboard"}
                        showNavBar={false}
                        elevate={0}
                />
                <main className={classes.mainTabbed}>
                  <Dashboard prjct={authState.prjct} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path={["/projects"]}>
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Projects"}
                        showNavBar={false} />
                <main className={classes.main}>
                  <Projects />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path={["/activity/:activityId"]}>
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Activities"}
                        showNavBar={false} />
                <main className={classes.main}>
                  <Activity/>
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path={["/activities/:tab"]}>
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Activities"}
                        showNavBar={false}
                        elevate={0} />
                <main className={classes.mainTabbed}>
                  <TabbedActivities prjct={authState.prjct} state={'ALL'} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path={["/planned-activities/:tab"]}>
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Planned Activities"}
                        showNavBar={false}
                        elevate={0} />
                <main className={classes.mainTabbed}>
                  <TabbedActivities prjct={authState.prjct}  state={'PLANNED'} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path={["/module-indicators/:tab"]}>
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Module Goal / Outcome Indicators"}
                        showNavBar={false}
                        elevate={0} />
                <main className={classes.mainTabbed}>
                  <ModuleIndicators prjct={authState.prjct} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path={["/output-indicators/:tab"]}>
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Output Indicators"}
                        showNavBar={false}
                        elevate={0} />
                <main className={classes.mainTabbed}>
                  <OutputIndicators prjct={authState.prjct} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path={["/cluster-indicators"]}>
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Cluster Indicators"}
                        showNavBar={false} />
                <main className={classes.main}>
                  <ClusterIndicators />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path={["/risk-indicators/:tab"]}>
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Risk Indicators"}
                        showNavBar={false}
                        elevate={0}  />
                <main className={classes.mainTabbed}>
                  <RiskIndicators />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path={["/users"]}>
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Users"}
                        showNavBar={false}  />
                <main className={classes.main}>
                  <UserSettings />
                </main>
              </ProtectedRoute>
              <Route exact path={["/imprint"]}>
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Imprint"}
                        showNavBar={false}  />
                <main className={classes.main}>
                  <Imprint />
                </main>
              </Route>
              <Route exact path={["/data-protection"]}>
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Data Protection Policy"}
                        showNavBar={false}  />
                <main className={classes.main}>
                  <DataProtection />
                </main>
              </Route>
              <Route path='/authentication' component={() => {
                window.location.href =
                    `${process.env.REACT_APP_FIREBASE_REDIRECT_URL}?mode=${query.get('mode')}&oobCode=${query.get('oobCode')}&apiKey=${query.get('apiKey')}&lang=${query.get('lang')}`;
                return null;
              }}/>
            </Switch>
          </BrowserRouter>
          <footer className={classes.footer}>
            <Copyright />
          </footer>
        </div>
      </div>
    </ThemeProvider>
  );
}

Paperbase.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Paperbase);
