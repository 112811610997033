import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import {FormHelperText, TextField} from "@material-ui/core";
import BasicAutocomplete from "../autocompletes/BasicAutocomplete";
import {searchMunicipalities, searchProjects} from "../../utils/api";
import * as Yup from "yup";
import {useFormik} from "formik";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
});

function AddActivityDialog(props) {
  const { classes, confirmationHandler } = props;
  const [open, setOpen] = React.useState(false);

  const ActivitySchema = Yup.object().shape({
    activityType: Yup.string().required('Required'),
    name: Yup.string().required('Required'),
    daysAllocated: Yup.number().min(1, 'Must be at least 1')
      .required('Required'),
    startDate: Yup.date().required('Required'),
    endDate: Yup.date().required('Required'),
    municipality: Yup.object().nullable().required('Required'),
    project: Yup.object().nullable().required('Required'),
  });

  const activityFormik = useFormik({
    initialValues: {
      activityType: '',
      name: '',
      daysAllocated: 1,
      startDate: '',
      endDate: '',
      municipality: null,
      project: null,
    },
    validationSchema: ActivitySchema,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async () => {
    setOpen(false);
    await activityFormik.resetForm();
  };

  const handleConfirm = () => {
    confirmationHandler(activityFormik.values);
    handleClose();
  };

  return (
      <div>
        <Fab
            variant="extended"
            size="medium"
            color="primary"
            aria-label="add"
            onClick={handleClickOpen}
            className={classes.fab}
            style={{position: 'fixed'}}
        >
          <AddIcon/>
          Add activity
        </Fab>
        <Dialog fullWidth={true} maxWidth={'sm'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Add new activity</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the details of the activity
            </DialogContentText>
            <FormControl variant="filled" className={classes.formControl} style={{width: '15rem'}} error={Boolean(activityFormik.errors.activityType)}>
              <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                Type
              </InputLabel>
              <Select
                  autoFocus
                  labelId="activity-type-select"
                  id="activity-type-select"
                  variant={'outlined'}
                  fullWidth
                  value={activityFormik.values.activityType}
                  onChange={(event) => activityFormik.setFieldValue("activityType", event.target.value)}
              >
                <MenuItem value={'WORKSHOP'}>Workshop</MenuItem>
                <MenuItem value={'TRAINING'}>Training</MenuItem>
                <MenuItem value={'CONFERENCE'}>Conference</MenuItem>
                <MenuItem value={'FORMAL_MEETING'}>Formal meeting</MenuItem>
                <MenuItem value={'INFORMAL_MEETING'}>Informal meeting</MenuItem>
                <MenuItem value={'NETWORKING'}>Networking / Knowledge Exchange</MenuItem>
                <MenuItem value={'STUDY_TRIP'}>Study Trip</MenuItem>
                <MenuItem value={'HANDOVER'}>Handover of materials / equipment</MenuItem>
                <MenuItem value={'CONSTRUCTION'}>Construction / Rehabilitation</MenuItem>
                <MenuItem value={'STUDY'}>Study</MenuItem>
                <MenuItem value={'PUBLICATION'}>Publication</MenuItem>
                <MenuItem value={'OTHER'}>Other</MenuItem>
              </Select>
              { Boolean(activityFormik.errors.activityType) &&
                <FormHelperText>{activityFormik.errors.activityType}</FormHelperText>
              }
            </FormControl>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    autoFocus
                    margin="normal"
                    id="name"
                    label="Name"
                    type="text"
                    variant={'outlined'}
                    value={activityFormik.values.name}
                    onChange={activityFormik.handleChange}
                    error={Boolean(activityFormik.errors.name)}
                    helperText={activityFormik.errors.name}
                />
              </FormControl>
            </div>
            <div>
              <TextField
                  margin="normal"
                  id="daysAllocated"
                  label="Days allocated"
                  type="number"
                  variant={'outlined'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={activityFormik.values.daysAllocated}
                  onChange={activityFormik.handleChange}
                  error={Boolean(activityFormik.errors.daysAllocated)}
                  helperText={activityFormik.errors.daysAllocated}
              />
            </div>
            <div>
              <TextField
                  margin="normal"
                  id="startDate"
                  label="Start date"
                  type="date"
                  variant={'outlined'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={activityFormik.values.startDate}
                  onChange={activityFormik.handleChange}
                  error={Boolean(activityFormik.errors.startDate)}
                  helperText={activityFormik.errors.startDate}
              />
            </div>
            <div>
              <TextField
                  margin="normal"
                  id="endDate"
                  label="End date"
                  type="date"
                  variant={'outlined'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={activityFormik.values.endDate}
                  onChange={activityFormik.handleChange}
                  error={Boolean(activityFormik.errors.endDate)}
                  helperText={activityFormik.errors.endDate}
              />
            </div>
            <BasicAutocomplete
                searchFct={searchMunicipalities}
                label={'Municipality'}
                property={'englishName'}
                scndProperty={'arabicName'}
                currentValue={activityFormik.values.municipality}
                handleChange={(value) => activityFormik.setFieldValue("municipality", value)}
                error={Boolean(activityFormik.errors.municipality)}
                helperText={activityFormik.errors.municipality}
            />
            <BasicAutocomplete
                searchFct={searchProjects}
                label={'Project'}
                property={'title'}
                currentValue={activityFormik.values.project}
                handleChange={(value) => activityFormik.setFieldValue("project", value)}
                error={Boolean(activityFormik.errors.project)}
                helperText={activityFormik.errors.project}/>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button disabled={!activityFormik.dirty || Object.keys(activityFormik.errors).length !== 0} onClick={handleConfirm} color="primary">
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(AddActivityDialog)