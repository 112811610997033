import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import BasicAutocomplete from "../autocompletes/BasicAutocomplete";
import {getIndicators} from "../../utils/api";
import {Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Typography from "@material-ui/core/Typography";

export default function AddIndicatorWithValueDialog(props) {
  const { activity, entity, confirmationHandler, clusterConfirmationHandler, action = 'create' } = props;
  const [open, setOpen] = useState(false);
  const [selectedIndicator, setSelectedIndicator] = useState(undefined);
  const [indicatorValueDescription, setIndicatorValueDescription] = useState('');
  const [indicatorValueComment, setIndicatorValueComment] = useState('');
  const [numericValue, setNumericValue] = useState(0);
  const [secondaryNumericValue, setSecondaryNumericValue] = useState(0);
  const [tertiaryNumericValue, setTertiaryNumericValue] = useState(0);
  const [fct, setFct] = useState('');
  const [females, setFemales] = useState(undefined);
  const [males, setMales] = useState(undefined);
  const [attributionDate, setAttributionDate] = useState(undefined);

  const handleClickOpen = () => {
    setOpen(true);
    setIndicatorValueDescription(entity && entity.description ? entity.description : '');
    setIndicatorValueComment(entity && entity.comment ? entity.comment : '');
    setNumericValue(entity && entity.numericValue ? entity.numericValue : 0);
    setSecondaryNumericValue(entity && entity.secondaryNumericValue ? entity.secondaryNumericValue : 0);
    setTertiaryNumericValue(entity && entity.tertiaryNumericValue ? entity.tertiaryNumericValue : 0);
    setFct(entity && entity.fct ? entity.fct : '');
    setFemales(entity && entity.nrFemales ? entity.nrFemales : undefined);
    setMales(entity && entity.nrMales ? entity.nrMales : undefined);
    setAttributionDate(entity && entity.attributionDate ? entity.attributionDate : undefined);
  };

  const handleClose = () => {
    setOpen(false);
    clearFormData();
  };

  const handleConfirm = () => {
    if (selectedIndicator && selectedIndicator.indicatorType !== 'CLUSTER') {
      action === 'create' ?
          confirmationHandler(
              selectedIndicator,
              indicatorValueDescription,
              activity,
              indicatorValueComment,
              numericValue,
              selectedIndicator && selectedIndicator.secondaryTargetValueNumeric ? secondaryNumericValue : null,
              selectedIndicator && selectedIndicator.tertiaryTargetValueNumeric ? tertiaryNumericValue : null) :
          confirmationHandler(
              entity.id,
              selectedIndicator,
              indicatorValueDescription,
              activity,
              indicatorValueComment,
              numericValue,
              selectedIndicator && selectedIndicator.secondaryTargetValueNumeric ? secondaryNumericValue : null,
              selectedIndicator && selectedIndicator.tertiaryTargetValueNumeric ? tertiaryNumericValue : null);
    } else {
      action === 'create' ?
          clusterConfirmationHandler(
              selectedIndicator,
              indicatorValueDescription,
              attributionDate,
              activity, fct, females, males, numericValue) :
          clusterConfirmationHandler(
              entity.id,
              selectedIndicator,
              indicatorValueDescription,
              attributionDate,
              activity, fct, females, males, numericValue);
    }

    handleClose();
  };

  const clearFormData = () => {
    setSelectedIndicator(undefined);
    setIndicatorValueDescription('');
    setIndicatorValueComment('');
    setNumericValue(0);
    setSecondaryNumericValue(0);
    setTertiaryNumericValue(0);
    setFct('');
    setFemales(undefined);
    setMales(undefined);
    setAttributionDate(undefined);
  };

  const onIndicatorValueDescriptionChange = (e) => {
    setIndicatorValueDescription(e.target.value);
  };

  const onIndicatorValueCommentChange = (e) => {
    setIndicatorValueComment(e.target.value);
  };

  const onNumericValueChange = (e) => {
    setNumericValue(e.target.value);
  };

  const onSecondaryNumericValueChange = (e) => {
    setSecondaryNumericValue(e.target.value);
  };

  const onTertiaryNumericValueChange = (e) => {
    setTertiaryNumericValue(e.target.value);
  };

  const onSelectedIndicatorChange = value => {
    setSelectedIndicator(value);
  };

  const onAttributionDateChange = (e) => {
    setAttributionDate(e.target.value);
  };

  return (
      <div>
        {action === 'create' &&
          <Button variant="outlined" color="primary" style={{ marginTop: '1rem' }} onClick={handleClickOpen}>
            Add indicator value
          </Button>
        }
        { action === 'edit' &&
        <Tooltip title="Edit">
          <IconButton aria-label="edit"
                      onClick={handleClickOpen}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        }
        <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{ action === 'create' ? 'Add new' : 'Edit'} indicator value</DialogTitle>
          <DialogContent>
            <DialogContentText>
              1. Please select an indicator first
            </DialogContentText>
            <BasicAutocomplete handleChange={onSelectedIndicatorChange} searchFct={getIndicators} groupBy={(option) => option.indicatorType} project={activity.project} currentValue={selectedIndicator} label={'Indicator'} property={'name'} forceIndicatorSort={true}/>
            { selectedIndicator &&
                <>
                  <br/>
                  <Typography variant="body1" component="div">
                    {selectedIndicator.description}
                  </Typography>
                  <br/>
                  <DialogContentText>
                    2. Add the relevant values
                  </DialogContentText>
                  <TextField
                      autoFocus
                      margin="normal"
                      id="name"
                      label="Description"
                      type="text"
                      value={indicatorValueDescription}
                      onChange={onIndicatorValueDescriptionChange}
                      fullWidth
                      variant="outlined"
                  />
                  {selectedIndicator && selectedIndicator.indicatorType !== 'CLUSTER' &&
                    <>
                      <TextField
                          margin="normal"
                          id="name"
                          label="Comment"
                          type="text"
                          value={indicatorValueComment}
                          onChange={onIndicatorValueCommentChange}
                          fullWidth
                          variant="outlined"
                      />
                      <TextField
                          margin="normal"
                          id="name"
                          label="Value"
                          value={numericValue}
                          onChange={onNumericValueChange}
                          type="number"
                          variant="outlined"
                      />
                    </>
                  }
                  { selectedIndicator && selectedIndicator.indicatorType !== 'CLUSTER' && selectedIndicator.secondaryTargetValueNumeric > 0 &&
                  <>
                    <Typography variant="subtitle1" component="div">
                      {selectedIndicator.secondaryTargetValueDescription}
                    </Typography>
                    <TextField
                        margin="normal"
                        id="name"
                        label="Secondary value"
                        value={secondaryNumericValue}
                        onChange={onSecondaryNumericValueChange}
                        type="number"
                        variant="outlined"
                    />
                  </>
                  }
                  { selectedIndicator && selectedIndicator.indicatorType !== 'CLUSTER' && selectedIndicator.tertiaryTargetValueNumeric > 0 &&
                  <>
                    <Typography variant="subtitle1" component="div">
                      {selectedIndicator.tertiaryTargetValueDescription}
                    </Typography>
                    <TextField
                        margin="normal"
                        id="name"
                        label="Tertiary value"
                        value={tertiaryNumericValue}
                        onChange={onTertiaryNumericValueChange}
                        type="number"
                        variant="outlined"
                    />
                  </>
                  }
                  { selectedIndicator && selectedIndicator.indicatorType === 'CLUSTER' &&
                  <>
                    <Typography variant="subtitle1" style={{ marginTop: '1rem' }} component="div">
                      Attribution date:
                    </Typography>
                    <TextField
                        margin="normal"
                        name="attributionDate"
                        type="date"
                        value={attributionDate}
                        disabled={false}
                        fullWidth={true}
                        variant="outlined"
                        onChange={onAttributionDateChange}
                    />
                  </>
                  }
                  { selectedIndicator && selectedIndicator.indicatorType === 'CLUSTER' && selectedIndicator.disaggregateFunction &&
                  <TextField
                      InputLabelProps={{ shrink: true }}
                      margin="normal"
                      id="function"
                      label="Function"
                      type="text"
                      value={fct}
                      onChange={(e) => setFct(e.target.value)}
                      fullWidth
                      variant="outlined"
                  />
                  }
                  { selectedIndicator && selectedIndicator.indicatorType === 'CLUSTER' && selectedIndicator.disaggregateGender &&
                  <>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        margin="normal"
                        id="females"
                        label="Female participants"
                        type="number"
                        value={females}
                        onChange={(e) => setFemales(e.target.value)}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        margin="normal"
                        id="males"
                        label="Male participants"
                        type="number"
                        value={males}
                        onChange={(e) => setMales(e.target.value)}
                        fullWidth
                        variant="outlined"
                    />
                  </>
                  }
                  { selectedIndicator && selectedIndicator.indicatorType === 'CLUSTER' && !selectedIndicator.disaggregateFunction && !selectedIndicator.disaggregateGender &&
                    <TextField
                        autoFocus
                        margin="normal"
                        id="name"
                        label="Value"
                        value={numericValue}
                        onChange={onNumericValueChange}
                        type="number"
                        variant="outlined"
                    />
                  }
                </>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              { action === 'create' ? 'Add' : 'Update'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}