import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import BasicAutocomplete from "../autocompletes/BasicAutocomplete";
import {searchActivities} from "../../utils/api";
import {Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Typography from "@material-ui/core/Typography";

export default function AddClusterIndicatorValueDialog(props) {
  const { indicator, entity, confirmationHandler, action = 'create', disableActivityUpdate = false } = props;
  const [open, setOpen] = useState(false);
  const [indicatorValueDescription, setIndicatorValueDescription] = useState('');
  const [indicatorValueComment, setIndicatorValueComment] = useState('');
  const [indicatorValueActivity, setIndicatorValueActivity] = useState(undefined);
  const [fct, setFct] = useState('');
  const [females, setFemales] = useState(undefined);
  const [males, setMales] = useState(undefined);
  const [numericValue, setNumericValue] = useState(0);
  const [attributionDate, setAttributionDate] = useState(undefined);

  const handleClickOpen = () => {
    setOpen(true);
    setIndicatorValueDescription(entity && entity.description ? entity.description : '');
    setIndicatorValueComment(entity && entity.comment ? entity.comment : '');
    setIndicatorValueActivity(entity && entity.activity ? entity.activity : undefined);
    setFct(entity && entity.fct ? entity.fct : '');
    setFemales(entity && entity.nrFemales ? entity.nrFemales : undefined);
    setMales(entity && entity.nrMales ? entity.nrMales : undefined);
    setNumericValue(entity && entity.numericValue ? entity.numericValue : 0);
    setAttributionDate(entity && entity.attributionDate ? entity.attributionDate : undefined);
  };

  const handleClose = () => {
    setOpen(false);
    clearFormData();
  };

  const handleConfirm = () => {
    action === 'create' ?
      confirmationHandler(
          indicator.id,
          indicatorValueDescription,
          attributionDate,
          indicatorValueActivity, fct, females, males, numericValue) :
        confirmationHandler(
            entity.id,
            indicator.id,
            indicatorValueDescription,
            attributionDate,
            indicatorValueActivity, fct, females, males, numericValue);
    handleClose();
  };

  const clearFormData = () => {
    setIndicatorValueDescription('');
    setIndicatorValueActivity(undefined);
    setFct('');
    setFemales(undefined);
    setMales(undefined);
    setNumericValue(0);
    setAttributionDate(undefined);
  };

  const onIndicatorValueDescriptionChange = (e) => {
    setIndicatorValueDescription(e.target.value);
  };

  const onNumericValueChange = (e) => {
    setNumericValue(e.target.value);
  };

  const onActivityChange = activity => {
    setIndicatorValueActivity(activity);
    if (activity && activity.numberParticipants >= 0 && activity.numberFemaleParticipants >= 0) {
      setFemales(activity.numberFemaleParticipants);
      setMales(activity.numberParticipants - activity.numberFemaleParticipants);
    }
  };

  const onAttributionDateChange = (e) => {
    setAttributionDate(e.target.value);
  };

  return (
      <div>
        {action === 'create' &&
          <Button variant="outlined" color="primary" onClick={handleClickOpen}>
            Add indicator value
          </Button>
        }
        { action === 'edit' &&
        <Tooltip title="Edit">
          <IconButton aria-label="edit"
                      onClick={handleClickOpen}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        }
        <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{ action === 'create' ? 'Add new' : 'Edit'} indicator value</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please fill in the necessary information
            </DialogContentText>
            <TextField
                autoFocus
                margin="normal"
                id="description"
                label="Description"
                type="text"
                value={indicatorValueDescription}
                onChange={onIndicatorValueDescriptionChange}
                fullWidth
                variant="outlined"
            />
            <Typography variant="subtitle1" style={{ marginTop: '1rem' }} component="div">
              Attribution date:
            </Typography>
            <TextField
                margin="normal"
                name="attributionDate"
                type="date"
                value={attributionDate}
                disabled={false}
                fullWidth={true}
                variant="outlined"
                onChange={onAttributionDateChange}
            />
            {!disableActivityUpdate &&
              <BasicAutocomplete handleChange={onActivityChange}
                                 searchFct={searchActivities}
                                 currentValue={indicatorValueActivity}
                                 label={'Activity'} property={'name'}/>
            }
            { indicator.disaggregateFunction &&
              <TextField
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                  id="function"
                  label="Function"
                  type="text"
                  value={fct}
                  onChange={(e) => setFct(e.target.value)}
                  fullWidth
                  variant="outlined"
              />
            }
            { indicator.disaggregateGender &&
              <>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    margin="normal"
                    id="females"
                    label="Female participants"
                    type="number"
                    value={females}
                    onChange={(e) => setFemales(e.target.value)}
                    fullWidth
                    variant="outlined"
                />
                <TextField
                    InputLabelProps={{ shrink: true }}
                    margin="normal"
                    id="males"
                    label="Male participants"
                    type="number"
                    value={males}
                    onChange={(e) => setMales(e.target.value)}
                    fullWidth
                    variant="outlined"
                />
              </>
            }
            { !indicator.disaggregateFunction && !indicator.disaggregateGender &&
              <TextField
                  autoFocus
                  margin="normal"
                  id="name"
                  label="Value"
                  value={numericValue}
                  onChange={onNumericValueChange}
                  type="number"
                  variant="outlined"
              />
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              { action === 'create' ? 'Add' : 'Update'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}