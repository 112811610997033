import React, {useContext, useEffect, useRef, useState} from 'react';
import {CircularProgress, Collapse, TextField} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import {
  getProjects,
  getStatistics,
} from "../../utils/api";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import {formatActivityType} from "../../utils/activityTypeHelper";
import {formatTime} from "../../utils/timeHelper";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Chart from "react-apexcharts";
import {UserContext} from "../auth/UserProvider";
import {Description, DescriptionList} from "../parts/Description.parts";
import GridList from "@material-ui/core/GridList";

const styles = () => ({
  paper: {
    padding: 15,
    marginTop: 10
  },
});

const arrSumFemales = arr => arr.filter(v => v.nrFemales !== undefined).reduce((a, b) => a + b.nrFemales, 0);
const arrSumMales = arr => arr.filter(v => v.nrMales !== undefined).reduce((a, b) => a + b.nrMales, 0);
const arrSum = arr => arr.filter(v => v.numericValue !== undefined).reduce((a, b) => a + b.numericValue, 0);

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  gridList: {
    paddingBottom: '1rem',
  },
  paper: {
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
});

function RiskStatsRow(props) {
  let {key, indicator} = props;
  const [open, setOpen] = useState(false);
  const [fromDate, setFromDate] = useState(undefined);
  const [toDate, setToDate] = useState(undefined);
  const classes = useRowStyles();

  let chartOptions = {
    chart: {
      id: "basic-line"
    },
    xaxis: {
      type: 'datetime'
    }
  };

  useEffect(() => {
    if (indicator && indicator.indicatorType === 'RISK') {
      let sortedValues =
          indicator.indicatorValues
          .sort(function(a,b){
            return new Date(a.attributionDate) - new Date(b.attributionDate);});

      if (sortedValues.length > 0) {
        setFromDate(sortedValues.length < 4 ? sortedValues[0].attributionDate : sortedValues[sortedValues.length - 4].attributionDate);
        setToDate(sortedValues[sortedValues.length - 1].attributionDate)
      }
    }
  }, []);

  let chartSeries = [];

  if (indicator.isNumeric && indicator.indicatorValues && indicator.indicatorValues.length > 0) {
    let series = { name: "Value"};

    let data = [];

    let sortedValues =
        indicator.indicatorValues
        .sort(function(a,b){
          return new Date(a.attributionDate) - new Date(b.attributionDate);});

    for (let i = 0; i < sortedValues.length; i++) {
      if (sortedValues[i].attributionDate >= fromDate && sortedValues[i].attributionDate <= toDate)  {
        data.push({ x: sortedValues[i].attributionDate, y: sortedValues[i].riskValue});
      }
    }

    series['data'] = data;

    chartSeries.push(series);
  }

  return <>
    <TableRow key={`${key}-head`} className={classes.root}>
      <TableCell/>
      <TableCell><strong>Name</strong></TableCell>
      <TableCell><strong>Description</strong></TableCell>
      <TableCell><strong>Current value</strong></TableCell>
    </TableRow>
    <TableRow key={key} className={classes.root}>
      <TableCell>
        {indicator.isNumeric &&
          <IconButton aria-label="expand row" size="small"
                      onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
          </IconButton>
        }
      </TableCell>
      <TableCell>{indicator.name}</TableCell>
      <TableCell>{indicator.description}</TableCell>
      <TableCell>{indicator.indicatorValues && indicator.indicatorValues.length > 0 ? indicator.indicatorValues[indicator.indicatorValues.length - 1].riskValue : ''}</TableCell>
    </TableRow>
    <TableRow key={`${key}-expanded`} className={classes.root}>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
        <Collapse style={{marginBottom: '1rem'}} in={open} timeout="auto" unmountOnExit>
          {indicator.isNumeric &&
            <>
              <div>
                <Chart
                    options={chartOptions}
                    series={chartSeries}
                    type="line"
                    width="500"
                />
              </div>
              <GridList cellHeight={'auto'} className={classes.gridList} cols={3}>
                <DescriptionList>
                  <Description>
                    <TextField
                        margin="normal"
                        id="fromDate"
                        label="From"
                        type="date"
                        value={fromDate}
                        variant={'outlined'}
                        onChange={(e) => setFromDate(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                    />
                  </Description>
                </DescriptionList>
                <DescriptionList>
                  <Description>
                    <TextField
                        margin="normal"
                        id="toDate"
                        label="To"
                        type="date"
                        value={toDate}
                        variant={'outlined'}
                        onChange={(e) => setToDate(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                    />
                  </Description>
                </DescriptionList>
              </GridList>
            </>
          }
        </Collapse>
      </TableCell>
    </TableRow>
  </>;

}

function ClusterStatsRow(props) {
  let {key, indicator, valuesByYear} = props;
  const classes = useRowStyles();
  const [open, setOpen] = useState(false);

  const year = new Date().getFullYear();

  let currentYearValues = valuesByYear.filter(v => v.year === year)[0];

  if (currentYearValues === undefined) {
    currentYearValues = {
      indicatorValues: []
    };
  }

  let chartOptions = {
    chart: {
      id: "basic-bar"
    },
    xaxis: {
      categories: valuesByYear.map(v => v.year)
    }
  };
  let chartSeries = [];

  if (indicator.disaggregateGender) {
    chartSeries = [
      {
        name: "Female participants",
        data: valuesByYear.map(v => arrSumFemales(v.indicatorValues))
      },
      {
        name: "Male participants",
        data: valuesByYear.map(v => arrSumMales(v.indicatorValues))
      },
    ];
  }

  if (!indicator.disaggregateGender) {
    chartSeries = [
      {
        name: "Amount",
        data: valuesByYear.map(v => arrSum(v.indicatorValues))
      }
    ];
  }

  return <>
    <TableRow key={`${key}-head`} className={classes.root}>
      <TableCell/>
      <TableCell><strong>Name</strong></TableCell>
      <TableCell><strong>Description</strong></TableCell>
      <TableCell><strong>Current value {year}</strong></TableCell>
    </TableRow>
    <TableRow key={key} className={classes.root}>
      <TableCell>
        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </TableCell>
      <TableCell>{indicator.name}</TableCell>
      <TableCell>{indicator.description}</TableCell>
      <TableCell>
        { indicator.disaggregateGender &&
        <>{`Female: ${arrSumFemales(currentYearValues.indicatorValues)} Male: ${arrSumMales(currentYearValues.indicatorValues)}`}</>
        }
        { !indicator.disaggregateGender &&
        <>{`Total: ${arrSum(currentYearValues.indicatorValues)}`}</>
        }
      </TableCell>
    </TableRow>
    <TableRow key={`${key}-expanded`} className={classes.root}>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
        <Collapse style={{marginBottom: '1rem'}} in={open} timeout="auto" unmountOnExit>
          {valuesByYear.length > 0 &&
              <div>Past years:</div>
          }
          <ul>
            {valuesByYear.map((yearValues) => (
                <li>{`${yearValues.year}: ${indicator.disaggregateGender ? `Female: ${arrSumFemales(yearValues.indicatorValues)} Male: ${arrSumMales(yearValues.indicatorValues)}` : `Total: ${arrSum(currentYearValues.indicatorValues)}`}`}</li>
            ))}
          </ul>
          <div>
            <Chart
                options={chartOptions}
                series={chartSeries}
                type="bar"
                width="500"
            />
          </div>
        </Collapse>
      </TableCell>
    </TableRow>
  </>;

}

function Dashboard(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isForbidden, setIsForbidden] = useState(false);
  const [statistics, setStatistics] = useState('');

  const cancelRequest = useRef();

  const authState = useContext(UserContext);

  const fetchData = async loading => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getStatistics();
          const statisticsData = result.data;

          if (!cancelRequest.current) {
            if (statisticsData && Object.keys(statisticsData).length > 0) {
              setStatistics(statisticsData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (error.response && error.response.status === 403) {
            setIsForbidden(true);
          } else if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  const fetchProjects = async (loading) => {
    setIsError(false);
    setIsLoading(loading);
    try {
      const result = await getProjects();
      const pojectsData = result.data;

      if (!cancelRequest.current) {
        if (pojectsData && Object.keys(pojectsData).length > 0) {
          setIsLoading(false);
        } else {
          setIsError(true);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setIsForbidden(true);
      } else if (!cancelRequest.current) {
        setIsError(true);
      }
    }
  };

  useEffect(() => {
    fetchData(false);
    fetchProjects(true);
  }, []);

  const position = [26.3347113, 17.2692101];

  const pieChartDataSeries = [];
  const pieChartDataOptions = {
    labels: [],
    legend: {
      show: true,
      position: 'bottom',
    },
    colors: [
        '#003f5c',
      '#2f4b7c',
      '#665191',
      '#a05195',
      '#d45087',
      '#f95d6a',
      '#ff7c43',
      '#ffa600'
    ],
  };

  if (statistics.activityTypes) {
    statistics.activityTypes.map((type) => {
      pieChartDataSeries.push(type.amount);
      pieChartDataOptions.labels.push(formatActivityType(type.activityType));
    })
  }

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isForbidden) {
      return (
          <>
            Access denied!
          </>
      );
    }

    if (isLoading || !statistics) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper style={{ height: '100%' }} variant="outlined" className={classes.paper}>
              <Typography variant="h5" color="textPrimary" style={{paddingBottom: '1rem'}}>
                Map of ongoing/upcoming activities
              </Typography>
              <MapContainer center={position} zoom={5} scrollWheelZoom={false} style={{height: 450, paddingTop: '2rem'}}>
                <TileLayer
                    attribution='&copy; <a href="https://carto.com/">carto.com</a> contributors'
                    url='https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png'
                />
                { statistics.municipalityStatistics && statistics.municipalityStatistics.map((municipality) => (
                    <>
                      { municipality.lat && municipality.lng &&
                        <Marker
                            position={[municipality.lat, municipality.lng]} key={municipality.id}>
                          <Popup>
                            <div style={{fontSize: 14, fontWeight: 'bold'}}>{municipality.englishName}</div>
                            {municipality.ongoingActivities && municipality.ongoingActivities.length > 0 &&
                            <strong>Ongoing:</strong>
                            }
                            <ul>
                              {municipality.ongoingActivities && municipality.ongoingActivities.map((a) => (
                                  <li><a href={'/activity/' + a.id}>{`${a.name} (${formatTime(a.startDate)}) ${a.project ? '- ' + a.project.titleShort : ''}`}</a></li>
                              ))}
                            </ul>
                            {municipality.activitiesPlanned && municipality.activitiesPlanned.length > 0 &&
                            <strong>Planned:</strong>
                            }
                            <ul>
                              {municipality.activitiesPlanned && municipality.activitiesPlanned.map((a) => (
                                  <li><a href={'/activity/' + a.id}>{`${a.name} (${formatTime(a.startDate)}) ${a.project ? '- ' + a.project.titleShort : ''}`}</a></li>
                              ))}
                            </ul>
                          </Popup>
                        </Marker>
                      }
                    </>
                ))}
              </MapContainer>
            </Paper>
          </Grid>
          {authState.role && authState.role === 'ROLE_SUPER' &&
            <Grid item xs={12} md={6}>
              <Paper style={{height: '100%'}} variant="outlined"
                     className={classes.paper}>
                <Typography variant="h5" color="textPrimary"
                            style={{paddingBottom: '1rem'}}>
                  Activity types
                </Typography>
                <Chart options={pieChartDataOptions} series={pieChartDataSeries} type="pie" />
              </Paper>
            </Grid>
          }
          {authState.role && authState.role === 'ROLE_SUPER' &&
            <Grid item xs={12} md={6}>
              <Paper style={{height: '100%'}} variant="outlined"
                     className={classes.paper}>
                <Typography variant="h5" color="textPrimary"
                            style={{paddingBottom: '1rem'}}>
                  Recently finalized activities
                </Typography>
                {statistics.pastActivities.length > 0 &&
                <TableContainer>
                  <Table aria-label="collapsible table">
                    <TableHead className={classes.tableHead}>
                      <TableRow>
                        <TableCell width={'40%'}>Name</TableCell>
                        <TableCell width={'30%'}>Type</TableCell>
                        <TableCell width={'30%'}>Start date</TableCell>
                        <TableCell width={'30%'}>Project</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {statistics.pastActivities
                      && statistics.pastActivities.map(
                          (activity) => (
                              <TableRow key={activity.id}>
                                <TableCell component="th"
                                           scope="row"><a href={'/activity/' + activity.id}>{activity.name}</a></TableCell>
                                <TableCell>{formatActivityType(
                                    activity.activityType)}</TableCell>
                                <TableCell>{formatTime(
                                    activity.startDate)}</TableCell>
                                <TableCell>{activity.project ? activity.project.title : ''}</TableCell>
                              </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                }
                {statistics.pastActivities.length === 0 &&
                <Typography variant="subtitle1" color="textPrimary"
                            style={{paddingBottom: '1rem'}}>
                  There are no past activities yet!
                </Typography>
                }
              </Paper>
            </Grid>
          }
          <Grid item xs={12} md={6}>
            <Paper style={{ height: '100%' }} variant="outlined" className={classes.paper}>
              <Typography variant="h5" color="textPrimary" style={{paddingBottom: '1rem'}}>
                Ongoing / Upcoming activities
              </Typography>
              {statistics.upcomingActivities.length > 0 &&
              <TableContainer>
                <Table aria-label="collapsible table">
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <TableCell width={'40%'}>Name</TableCell>
                      <TableCell width={'30%'}>Type</TableCell>
                      <TableCell width={'30%'}>Start date</TableCell>
                      <TableCell width={'30%'}>Project</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {statistics.upcomingActivities
                    && statistics.upcomingActivities.map(
                        (activity) => (
                            <TableRow key={activity.id}>
                              <TableCell component="th"
                                         scope="row"><a href={'/activity/' + activity.id}>{activity.name}</a></TableCell>
                              <TableCell>{formatActivityType(
                                  activity.activityType)}</TableCell>
                              <TableCell>{formatTime(
                                  activity.startDate)}</TableCell>
                              <TableCell>{activity.project ? activity.project.title : ''}</TableCell>
                            </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </TableContainer>
              }
              {statistics.upcomingActivities.length === 0 &&
              <Typography variant="subtitle1" color="textPrimary" style={{paddingBottom: '1rem'}}>
                There are no upcoming activities yet!
              </Typography>
              }
            </Paper>
          </Grid>
          {authState.role && authState.role === 'ROLE_SUPER' &&
            <Grid item xs={12} md={12}>
              <Paper style={{height: '100%'}} variant="outlined"
                     className={classes.paper}>
                <Typography variant="h5" color="textPrimary"
                            style={{paddingBottom: '1rem'}}>
                  Cluster Indicators
                </Typography>
                {statistics.clusterIndicators && statistics.clusterIndicators.map(
                    (cIndicator, idx) => (
                        <ClusterStatsRow key={idx}
                                         indicator={cIndicator.indicator}
                                         valuesByYear={cIndicator.indicatorValuesByYear}/>
                    ))}
              </Paper>
            </Grid>
          }
          {authState.role && authState.role === 'ROLE_SUPER' &&
            <Grid item xs={12} md={12}>
              <Paper style={{height: '100%'}} variant="outlined"
                     className={classes.paper}>
                <Typography variant="h5" color="textPrimary"
                            style={{paddingBottom: '1rem'}}>
                  Risk Indicators
                </Typography>
                {statistics.riskIndicators && statistics.riskIndicators.map(
                    (rIndicator, idx) => (
                        <RiskStatsRow key={idx} indicator={rIndicator}/>
                    ))}
              </Paper>
            </Grid>
          }
        </Grid>
      </>
    );
  };

  return renderData();
}

export default withStyles(styles)(Dashboard);